<cer-form *ngIf="showWarehouse" #form [panes]="panesSalesWarehouse">
  <ng-template #topTemplate>
    <cer-grid #salesGrid id="salesGrid" [viewMetadata]="salesViewMetadata" [fieldMetadata]="salesFieldMetadata"
      [fieldGroupMetadata]="salesFieldGroupMetadata" [toolbarCommands]="salesToolbarCommands"
      [selectionMode]="salesSelectionMode" [contextMenuCommands]="salesContextMenuCommands" [dataParent]="true"
      paging="virtual"></cer-grid>
  </ng-template>
  <ng-template #mainTemplate>
    <cer-grid #dataNoteGrid id="dataNoteGrid" [viewMetadata]="dataNoteViewMetadata"
      [fieldMetadata]="dataNoteFieldMetadata" [dataChild]="true" dataChildOfId="salesGrid">
    </cer-grid>
  </ng-template>
  <ng-template #rightTemplate>
    <cer-grid #outboundGrid id="outboundGrid" [viewMetadata]="outboundViewMetadata"
      [toolbarCommands]="outboundToolbarCommands" [contextMenuCommands]="outboundContextMenuCommands"
      [fieldMetadata]="outboundFieldMetadata" [fieldGroupMetadata]="outboundFieldGroupMetadata" [dataParent]="true"
      [dataChild]="true" dataChildOfId="salesGrid">
    </cer-grid>
  </ng-template>
  <ng-template #bottomTemplate>
    <cer-grid #lineGrid id="lineGrid" [viewMetadata]="lineViewMetadata" [fieldMetadata]="lineFieldMetadata"
      [dataChild]="true" dataChildOfId="salesGrid">
    </cer-grid>
  </ng-template>
  <ng-template #bottomRightTemplate>
    <cer-grid #outboundLineGrid id="outboundLineGrid" [viewMetadata]="outboundLineViewMetadata" [dataChild]="true"
      dataChildOfId="outboundGrid" [fieldMetadata]="outboundLineFieldMetadata">
    </cer-grid>
  </ng-template>
</cer-form>
<cer-form *ngIf="!showWarehouse" #form [panes]="panesSales">
  <ng-template #topTemplate>
    <cer-grid #salesGrid id="salesGrid" [viewMetadata]="salesViewMetadata" [fieldMetadata]="salesFieldMetadata"
      [fieldGroupMetadata]="salesFieldGroupMetadata" [toolbarCommands]="salesToolbarCommands"
      [selectionMode]="salesSelectionMode" [contextMenuCommands]="salesContextMenuCommands" [dataParent]="true"
      paging="virtual">
    </cer-grid>
  </ng-template>
  <ng-template #mainTemplate>
    <cer-grid #lineGrid id="lineGrid" [viewMetadata]="lineViewMetadata" [fieldMetadata]="lineFieldMetadata"
      [dataChild]="true" dataChildOfId="salesGrid"></cer-grid>
  </ng-template>
  <ng-template #bottomTemplate>
    <cer-grid #dataNoteGrid id="dataNoteGrid" [viewMetadata]="dataNoteViewMetadata"
      [fieldMetadata]="dataNoteFieldMetadata" [dataChild]="true" dataChildOfId="salesGrid"></cer-grid>
  </ng-template>
</cer-form>