<div style="z-index: 1; position: absolute; padding: 10px">
  <p *ngIf="!dataIsReady">Henter data ...</p>
</div>
<div
  *ngIf="dataIsReady"
  [class.pageIsLoading]="pageIsLoading"
  id="container"
  style="z-index: 0; height: 100%"
>
  <div
    style="
      flex-direction: column;
      display: flex;
      height: 100%;
      border-style: none;
    "
  >
    <div style="flex-grow: 1; height: 100%">
      <div
        id="mainGroup"
        style="flex-direction: column; display: flex; height: 100%"
        class="flex-grow: 1"
      >
        <ejs-splitter
          #splitterInstance
          #horisontal
          width="100%; border-style: none;"
          (resizing)="onSplitterResizing($event)"
          (expanded)="onSplitterExpanded($event)"
        >
          <e-panes>
            <e-pane size="65%">
              <ng-template #content>
                <div
                  id="mainGroup"
                  class="flex flex-column"
                  style="height: 100%"
                >
                  <div style="flex-grow: 0; padding-top: 5px; padding-left: 10px;">
                    <h3 style="margin-bottom: 4px">
                      <span *ngIf="isExpenseVoucher">Udgiftsbilag</span
                      ><span *ngIf="!isExpenseVoucher">Fakturagodkendelse</span
                      ><span *ngIf="fromName">&nbsp;fra {{ fromName }}</span
                      >:
                    </h3>
                  </div>
                  <div style="flex-grow: 0">
                    <ejs-grid
                      #voucherGrid
                      id="VoucherGrid"
                      [dataSource]="voucherDtoList"
                      [allowSorting]="false"
                      [allowReordering]="true"
                      [allowResizing]="true"
                      [allowGrouping]="true"
                      [allowExcelExport]="true"
                      [showColumnChooser]="true"
                      [editSettings]="voucherGridEditSettings"
                      [groupSettings]="voucherGridGroupSettings"
                      [toolbar]="voucherGridToolbar"
                      [contextMenuItems]="voucherGridContextMenuItems"
                      (dataBound)="voucherGridDataBound($event)"
                      (actionBegin)="voucherGridActionBegin($event)"
                      (actionComplete)="voucherGridActionComplete($event)"
                      (actionFailure)="voucherGridActionFailure($event)"
                      (rowSelected)="voucherGridRowSelected($event)"
                      (toolbarClick)="voucherGridToolbarClick($event)"
                      (recordDoubleClick)="voucherGridRecordDoubleClick($event)"
                      (contextMenuOpen)="voucherGridContextMenuOpen($event)"
                      (contextMenuClick)="voucherGridContextMenuClick($event)"
                    >
                      <e-columns>
                        <e-column
                          field="id"
                          headerText="Bilag ID"
                          [isPrimaryKey]="true"
                          [isIdentity]="true"
                          [allowEditing]="false"
                          [visible]="false"
                          width="90px"
                        >
                        </e-column>
                        <e-column
                          field="voucherNum"
                          headerText="Bilag"
                          [allowEditing]="false"
                          [visible]="isAnyVoucherPosted"
                          width="100px"
                        >
                        </e-column>
                        <e-column
                          field="postedStatusName"
                          headerText="Bogføring"
                          [allowEditing]="false"
                          [visible]="isAnyVoucherPosted"
                          width="100px"
                        ></e-column>
                        <e-column
                          field="statusName"
                          headerText="Status"
                          [allowEditing]="false"
                          [visible]="isStatusColumnVisible"
                          width="100px"
                        ></e-column>
                        <e-column
                          field="balanceAccountNum"
                          [headerText]="isExpenseVoucher ? 'Konto' : 'Kreditor'"
                          width="90px"
                          editType="dropdownedit"
                          [allowEditing]="userIsAdmin && !isExpenseVoucher"
                          [visible]="!isExpenseVoucher"
                          [edit]="voucherVendorAccountNumEditCell"
                        >
                        </e-column>
                        <e-column
                          field="balanceAccountDescription"
                          [headerText]="
                            isExpenseVoucher ? 'Kontonavn' : 'Kreditornavn'
                          "
                          [allowEditing]="false"
                          [visible]="!userIsAdmin && !isExpenseVoucher"
                          width="110px"
                        ></e-column>
                        <e-column
                          field="documentDate"
                          headerText="Dokumentdato"
                          type="date"
                          [format]="dateFormat"
                          editType="datepickeredit"
                          [allowEditing]="userIsAdmin || voucherIsStatusCreated"
                          [visible]="false"
                          width="90px"
                        ></e-column>
                        <e-column
                          field="transDate"
                          headerText="Dato"
                          type="date"
                          [format]="dateFormat"
                          editType="datepickeredit"
                          [allowEditing]="userIsAdmin || voucherIsStatusCreated"
                          width="90px"
                        ></e-column>
                        <e-column
                          field="documentNum"
                          headerText="Fakturanr."
                          [allowEditing]="userIsAdmin"
                          width="100px"
                        ></e-column>
                        <e-column
                          field="authReference"
                          headerText="Nota Indløser"
                          [visible]="isExpenseVoucher && userIsAdmin"
                          width="60px"
                        ></e-column>
                        <e-column
                          field="amountCur"
                          textAlign="Right"
                          headerText="Beløb i valuta"
                          headerTextAlign="Right"
                          editType="numericedit"
                          width="90px"
                          format="N2"
                          [allowEditing]="userIsAdmin || voucherIsStatusCreated"
                        ></e-column>
                        <e-column
                          field="currency"
                          headerText="Valuta"
                          editType="comboedit"
                          [allowEditing]="userIsAdmin || voucherIsStatusCreated"
                          width="60px"
                          foreignKeyValue="iso"
                          foreignKeyField="id"
                          [dataSource]="currenciesDM"
                          [edit]="currencyEditCell"
                        >
                        </e-column>
                        <e-column
                          field="voucherCategoryName"
                          headerText="Kategori"
                          width="100px"
                          [allowEditing]="false"
                          [visible]="categoryVisible"
                        ></e-column>
                        <e-column
                          field="costAccountNum"
                          headerText="Projekt / Finans"
                          [allowEditing]="userIsAdmin && !isExpenseVoucher"
                          [visible]="userIsAdmin && !isExpenseVoucher"
                          width="80px"
                          editType="dropdownedit"
                          [edit]="voucherProjectNumEditCell"
                        ></e-column>
                        <e-column
                          field="costAccountDescription"
                          headerText="Projektnavn"
                          [allowEditing]="false"
                          [visible]="false"
                          width="110px"
                        ></e-column>
                        <e-column
                          field="projectCostType"
                          foreignKeyValue="description"
                          foreignKeyField="num"
                          [dataSource]="projectCostTypesDM"
                          headerText="Projektart"
                          editType="dropdownedit"
                          [edit]="projectCostTypeEditCell"
                          [allowEditing]="userIsAdmin"
                          [visible]="userIsAdmin && !isExpenseVoucher"
                          width="100px"
                        ></e-column>
                        <e-column
                          field="approver"
                          headerText="Godkender"
                          editType="dropdownedit"
                          foreignKeyValue="shortName"
                          foreignKeyField="id"
                          [dataSource]="usersDM"
                          [edit]="approverEditCell"
                          width="100px"
                        >
                        </e-column>
                        <e-column
                          field="txt"
                          headerText="Tekst"
                          width="120px"
                          [visible]="userIsAdmin || voucherIsStatusCreated"
                        ></e-column>
                      </e-columns>
                    </ejs-grid>
                  </div>
                  <div style="flex-grow: 1; flex-shrink: 1; overflow: auto; margin-top: 4px">
                    <ejs-grid
                      #postingGrid
                      id="PostingGrid"
                      [dataSource]="postingDtoList"
                      [allowSorting]="false"
                      [allowReordering]="true"
                      [allowResizing]="true"
                      [allowExcelExport]="true"
                      [allowGrouping]="true"
                      [showColumnChooser]="true"
                      [groupSettings]="postingGridGroupSettings"
                      [editSettings]="postingGridEditSettings"
                      [toolbar]="postingGridToolbar"
                      [contextMenuItems]="postingGridContextMenuItems"
                      (load)="postingGridLoad($event)"
                      (dataBound)="postingGridDataBound($event)"
                      (actionBegin)="postingGridActionBegin($event)"
                      (actionComplete)="postingGridActionComplete($event)"
                      (rowSelected)="postingGridRowSelected($event)"
                      (recordDoubleClick)="postingGridRecordDoubleClick($event)"
                      (toolbarClick)="postingGridToolbarClick($event)"
                      (contextMenuClick)="postingGridContextMenuClick($event)"
                      (queryCellInfo)="postingGridQueryCellInfo($event)"
                    >
                      <e-columns>
                        <e-column
                          field="amountCur"
                          textAlign="Right"
                          headerText="Beløb i valuta"
                          headerTextAlign="Right"
                          editType="numericedit"
                          width="90px"
                          format="N2"
                        ></e-column>
                        <e-column
                          field="msgId"
                          headerText="Besked Id"
                          [visible]="false"
                          [isIdentity]="true"
                          width="90px"
                          [allowEditing]="false"
                        ></e-column>
                        <e-column
                          field="voucherId"
                          headerText="Bilag Id"
                          [visible]="false"
                          [isIdentity]="true"
                          width="90px"
                          [allowEditing]="false"
                        ></e-column>
                        <e-column
                          field="id"
                          headerText="Linje Id"
                          [visible]="false"
                          [isPrimaryKey]="true"
                          [isIdentity]="true"
                          width="90px"
                          [allowEditing]="false"
                        ></e-column>
                        <e-column
                          field="lineNum"
                          headerText="Linje"
                          [visible]="false"
                          width="90px"
                          [allowEditing]="false"
                        ></e-column>
                        <e-column
                          field="postingType"
                          foreignKeyValue="name"
                          foreignKeyField="id"
                          [dataSource]="postingTypesDM"
                          headerText="Type kontering"
                          width="80px"
                          editType="dropdownedit"
                          [visible]="userIsAdmin || isExpenseVoucher"
                          [edit]="postingTypeEditCell"
                        >
                        </e-column>
                        <e-column
                          field="accountNum"
                          [headerText]="
                            userIsAdmin || isExpenseVoucher
                              ? 'Nummer'
                              : 'Projekt'
                          "
                          width="100px"
                          editType="dropdownedit"
                          [edit]="accountNumEditCell"
                        >
                        </e-column>
                        <e-column
                          field="accountDescription"
                          [headerText]="
                            userIsAdmin || isExpenseVoucher
                              ? 'Navn'
                              : 'Projektnavn'
                          "
                          width="110px"
                          [allowEditing]="false"
                        >
                        </e-column>
                        <e-column
                          field="projectCostType"
                          foreignKeyValue="description"
                          foreignKeyField="num"
                          [dataSource]="projectCostTypesDM"
                          headerText="Projektart"
                          editType="dropdownedit"
                          [edit]="projectCostTypeEditCell"
                          width="100px"
                        >
                        </e-column>
                        <e-column
                          field="projectLedgerNum"
                          foreignKeyValue="numName"
                          foreignKeyField="num"
                          [dataSource]="ledgerAccountsDM"
                          headerText="Projektbogføring"
                          editType="dropdownedit"
                          [edit]="projectLedgerNumEditCell"
                          [allowEditing]="userIsAdmin"
                          [visible]="userIsAdmin"
                          width="100px"
                        >
                        </e-column>
                        <e-column
                          field="taxCode"
                          foreignKeyValue="num"
                          foreignKeyField="num"
                          headerText="Momskode"
                          [allowEditing]="userIsAdmin"
                          [visible]="userIsAdmin"
                          width="100px"
                          [dataSource]="taxCodesDM"
                          editType="dropdownedit"
                          [edit]="taxCodeEditCell"
                        ></e-column>
                        <e-column
                          field="dimension"
                          foreignKeyValue="num"
                          foreignKeyField="num"
                          headerText="Afdeling"
                          [allowEditing]="userIsAdmin"
                          [visible]="userIsAdmin"
                          width="100px"
                          [dataSource]="ledgerDimensionsDM"
                          editType="dropdownedit"
                          [edit]="ledgerDimensionEditCell"
                        ></e-column>
                        <e-column
                          field="vendorBankAccount"
                          headerText="Bankkonto"
                          width="120px"
                          [allowEditing]="false"
                          [visible]="userIsAdmin && !isExpenseVoucher"
                        >
                        </e-column>
                        <e-column
                          field="paymentRef"
                          headerText="Betalings Id"
                          width="40px"
                          [allowEditing]="true"
                          [visible]="userIsAdmin && !isExpenseVoucher"
                        >
                        </e-column>
                        <e-column
                          field="txt"
                          headerText="Posteringstekst"
                          width="220px"
                        ></e-column>
                        <e-column
                          field="modified"
                          headerText="Ændret"
                          width="120px"
                          [format]="dateTimeFormat"
                          [visible]="false"
                          [allowEditing]="false"
                        ></e-column>
                        <e-column
                          field="modifiedByShortName"
                          headerText="Ændret af"
                          width="100px"
                          [visible]="false"
                          [allowEditing]="false"
                        ></e-column>
                      </e-columns>
                      <e-aggregates>
                        <e-aggregate>
                          <e-columns>
                            <e-column
                              field="amountCur"
                              type="Custom"
                              textAlign="Right"
                              [customAggregate]="postingAmountCurAggregate"
                            >
                              <ng-template #footerTemplate let-data
                                ><span [innerHTML]="data.Custom"></span>
                              </ng-template>
                            </e-column>
                          </e-columns>
                        </e-aggregate>
                      </e-aggregates>
                    </ejs-grid>
                  </div>
                  <div
                    style="flex-grow: 0; margin-top: 12px; margin-bottom: 4px"
                  >
                    <mat-dialog-actions>
                      <button
                        *ngIf="canEdit"
                        mat-button
                        mat-raised-button
                        color="accent"
                        [class]="actionDisabledClass"
                        (click)="actionRun('ApprovedAndClose')"
                        [disabled]="!voucherCanApprove"
                      >
                        <i class="material-icons">forward</i>Godkend
                      </button>
                      <button *ngIf="canEdit && voucherCanSaveAndClose" mat-button mat-raised-button color="primary"
                        [class]="actionDisabledClass"
                        (click)="actionRun('SaveAndClose')" title="Gem og luk (Alt+S)" >
                        <i class="material-icons">check</i><span>Gem og luk</span>
                      </button>
                      <button *ngIf="canEdit && canSendToPending" mat-button mat-raised-button color="primary"
                        [class]="actionDisabledClass"
                        (click)="actionRun('PendingApprovalAndClose')" title="Send til godkendelse og luk (Alt+G)" >
                        <i class="material-icons">check</i><span>Send til godkendelse og luk</span>
                      </button>
                      <button *ngIf="canEdit && canNewApprover" mat-button mat-raised-button color="warn"
                        [class]="actionDisabledClass"
                        (click)="actionRun('NewApproverAndClose')">
                        <i class="material-icons">forward</i><span>Ny godkender</span>
                      </button>
                      <button *ngIf="canEdit && voucherCanHold" mat-button mat-raised-button color="warn"
                        [class]="actionDisabledClass"
                        (click)="actionRun('OnHoldAndClose')">
                        <i class="material-icons">pause</i><span>Afventer</span>
                      </button>
                      <button mat-button mat-raised-button cdkFocusInitial
                        (click)="actionRun('Close')">
                        <i class="material-icons">close</i><span>Luk</span>
                      </button>
                    </mat-dialog-actions>
                  </div>
                </div>
              </ng-template>
            </e-pane>
            <e-pane
              [collapsible]="true"
              [collapsed]="true"
              size="35%"
            >
              <ng-template #content>
                <cer-app-file-viewer-tab
                  #tabAttachments
                  *ngIf="attachmentsIsActive"
                  [formServiceActive]="false"
                  [token]="detailsVm?.voucherMessageViewDto?.messageToken"
                  [refTableId]="1"
                  [refRowId]="detailsVm?.voucherMessageViewDto?.id"
                  [enableChat]="true"
                  [chats]="detailsVm?.chatDtoList"
                  [attachments]="attachments"
                  [saveUrl]="detailsVm?.attachmentsSaveUrl"
                  [removeUrl]="detailsVm?.attachmentsRemoveUrl"
                  [tabCheckBoxAllowed]="userIsAdmin"
                  [privateNoteActive]="userIsAdmin"
                  (fileUploadChanged)="onAttachmentUploadChange($event)"
                  (tabSelectedIndexChanged)="onAttachmentTabSelectedIndexChanged($event)"
                  (tabCheckBoxApply)="onAttachmentCheckBoxApply($event)"
                ></cer-app-file-viewer-tab>
              </ng-template>
            </e-pane>
          </e-panes>
        </ejs-splitter>
      </div>
    </div>
  </div>
</div>
