import { Injector } from "@angular/core";
import { FieldGroupMetadata, FieldMetadata, ViewMetadata } from "../../cer/cer-data/cer-data.service";
import { AppStateService } from '../../app-core/app-state/app-state.service';
import { CerFormComponent } from "../../cer/cer-form/cer-form.component";
import { CerFormPaneVisible } from "src/cer/cer-form/cer-form-panes.service";
import { UiCommand, UiCommandEvent, UiKeyboardShortcut } from "../../cer/cer-grid/cer-grid-command.service";
import { CerGridComponent, CerGridSelectionMode } from "../../cer/cer-grid/cer-grid.component";
import { CerDialogService } from "src/cer/cer-dialog/cer-dialog.service";
import { CerAppRouteService } from "src/cer-app/cer-app-route/cer-app-route.service";
import { CerAppDialogService } from "src/cer-app/cer-app-dialog/cer-app-dialog.service";
import { Subject } from "rxjs";

export class CerAppFormModelAdapter {
  //public command$: Subject<UiCommandEvent> = new Subject<UiCommandEvent>();
  public viewMetadata: ViewMetadata;
  public fieldGroupMetadata: FieldGroupMetadata[]
  public fieldMetadata: FieldMetadata[];
  public toolbarCommands: UiCommand[];
  public contextMenuCommands: UiCommand[];
  public selectionMode: CerGridSelectionMode;


  public detailViewMetadata: ViewMetadata;
  public detailFieldGroupMetadata: FieldGroupMetadata[]
  public detailFieldMetadata: FieldMetadata[];
  public detailToolbarCommands: UiCommand[];
  public detailContextMenuCommands: UiCommand[];

  public detailSelectionMode: CerGridSelectionMode;

  public keyboardShortcuts: UiKeyboardShortcut[];

  //  public route: ActivatedRoute;
  public injector: Injector;

  public dialogService: CerDialogService;
  public appDialogService: CerAppDialogService;
  public appStateService: AppStateService;
  public routeService: CerAppRouteService;
  public routeParams: string[];
  public routeData: any;
  public form: CerFormComponent;
  public grid: CerGridComponent;

  public detailPaneVertical: boolean = false;
  public detailPaneVisible: CerFormPaneVisible = 'show';
  public detailPaneSizePct: number = 30;
  public detailGrid: CerGridComponent;

  public init() {
  }

  public onCommand(event: UiCommandEvent) {
  }

  public onDetailCommand(event: UiCommandEvent) {
  }

}
