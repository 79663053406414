import { Injectable } from "@angular/core";

import { CerAppDialogService } from "src/cer-app/cer-app-dialog/cer-app-dialog.service";
import { CerDialogService } from "src/cer/cer-dialog/cer-dialog.service";
import { FieldGroupMetadata, FieldMetadata, ViewMetadata } from "src/cer/cer-data/cer-data.service";
import { uiCmdSeparator, UiCommand, UiCommandEvent } from "src/cer/cer-grid/cer-grid-command.service";

import { CustInvoiceClient, CustInvoiceDocumentCommand } from "../api";

import { cmdCustInvoiceDocument, CustInvoiceView } from "./cust-invoice.view";


@Injectable({ providedIn: 'root' })
export class CustInvoiceService {

  constructor(
    private client: CustInvoiceClient,
    private ui: CerAppDialogService) {
  }

  public static tableView(routeParams: string[], isDraft: boolean): ViewMetadata {
    return CustInvoiceView.tableView(routeParams, isDraft);
  }

  public static tableFields(isDraft: boolean): FieldMetadata[] {
    return CustInvoiceView.tableFields(isDraft);
  }

  public static tableToolbar(isDraft: boolean, isCredit: boolean): UiCommand[] {
    return CustInvoiceView.tableToolbar(isDraft, isCredit);
  }

  public static lineView(isDraft: boolean): ViewMetadata {
    return CustInvoiceView.lineView(isDraft);
  }

  public static lineFields(isDraft: boolean): FieldMetadata[] {
    return CustInvoiceView.lineFields(isDraft);
  }

  public static lineToolbar(isDraft: boolean): UiCommand[] {
    return CustInvoiceView.lineToolbar(isDraft);
  }

  // Commands
  public onCommand(event: UiCommandEvent) {
    switch (event.commandId) {
      case cmdCustInvoiceDocument.id:
        this.documentGet(event);
        break;
    }
  }

  private documentGet(event: UiCommandEvent) {
    var id = event.rowData?.id;
    if (id) {
      var cmd: CustInvoiceDocumentCommand = new CustInvoiceDocumentCommand(
        { id: id, createDraft: false, createPDF: true });
      this.documentGetCommand(cmd, event);
    }
    else {
      this.ui.error('Ingen faktura markeret');
    }
  }

  private documentGetCommand(cmd: CustInvoiceDocumentCommand, event: UiCommandEvent) {
    this.ui.operationBegin('Henter faktura', 'Vent venligst...', 10000);
    this.client.document(cmd).subscribe({
      next: fileName => {
        this.ui.operationEnd();
        this.ui.openFileName(fileName);
        //refreshEvent(event);
      },
      error: (error) => {
        this.ui.operationError(error);
      }
    });
  }
}
