// Angular
import { Injectable, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

// SyncFusion
import { EventHandler, getComponent } from '@syncfusion/ej2-base';
import { Dialog } from '@syncfusion/ej2-angular-popups';
import { Column, ColumnModel, Edit, GridComponent } from '@syncfusion/ej2-angular-grids';
import { FormValidator } from '@syncfusion/ej2-angular-inputs';
import { CheckBox } from '@syncfusion/ej2-angular-buttons';
import { DropDownList } from '@syncfusion/ej2-angular-dropdowns';

// Third party
import { v4 as uuidv4 } from 'uuid';

import { CerDataService, FieldGroupMetadata, FieldMetadata } from '../cer-data/cer-data.service';
import { CerDialogService } from '../cer-dialog/cer-dialog.service';
import { CerEditMetaService } from '../cer-edit/cer-edit-meta.service';
import { CerEditTemplateService } from '../cer-edit/cer-edit-template.service';
import { GridDesign } from './cer-grid.component';
import { CerGridCommandService, UiActionTypeEnum, UiCommandEvent, UiCommandSourceEnum, UiKeyboardShortcut, uiCmdEditCancel, uiCmdEditModeTooggle, uiCmdEditStart } from './cer-grid-command.service';
import { CerGridSelectionService } from './cer-grid-selection.service';
import { CerDropDownList } from '../cer-control/cer-drop-down-list/cer-drop-down-list';


@Injectable()
export class CerGridEditService implements OnDestroy {

  // Subscriptions
  private subscriptionManager: Subscription = new Subscription();

  // Grid edit
  private grid: GridComponent;
  private design: GridDesign;

  // Keyboard event
  private dialogKeyboardShortcuts: UiKeyboardShortcut[] =
    [
      { cmd: uiCmdEditCancel, code: 'Escape', alt: false, ctrl: false, shift: false, enabled: true, allowsPreventDefault: true },
      { cmd: uiCmdEditCancel, code: 'Escape', alt: false, ctrl: true , shift: false, enabled: true, allowsPreventDefault: true },
      { cmd: uiCmdEditCancel, code: 'Escape', alt: false, ctrl: false, shift: true , enabled: true, allowsPreventDefault: true }
    ];

  public init(grid: GridComponent, design: GridDesign) {
    this.grid = grid;
    this.design = design;
  }

  public editedData: any;
  public editedDataOrig: any;
  public uuid: string; // Force unique id for dialog edit, making sure two saves are not successfull in the same edit
  private dialogFieldsVisibleSave: string[] = null;
  private dialogFieldsVisibleEdit: string[] = null;
  private dialogFieldsAllowEditSave: string[] = null;
  private dialogFieldsAllowEditEdit: string[] = null;

  public editAcceptUpdatePrimaryKey: boolean = false;

  constructor(
    private command: CerGridCommandService,
    private data: CerDataService,
    private meta: CerEditMetaService,
    private selection: CerGridSelectionService,
    private editMetaService: CerEditMetaService,
    private editTemplateService: CerEditTemplateService,
    private dialogService: CerDialogService
  ) {
    this.subscriptionManager.add(this.command.commandPre$.subscribe(event => this.onCommandPre(event)));
    this.subscriptionManager.add(this.command.commandPost$.subscribe(event => this.onCommandPost(event)));
  }

  ngOnDestroy(): void {
    this.subscriptionManager.unsubscribe();
  }

  // Editing
  public ej2Module(): Edit {
    return this.grid.editModule;
  }

  public formValidator(): FormValidator {
    return this.ej2Module()?.formObj;
  }

  public formElement(): HTMLFormElement {
    var formElement: HTMLFormElement = this.formValidator()?.element;
    return formElement;
  }

  public editData(): any {
    var editData: any = null;
    var formElement: HTMLFormElement = this.formElement();
    if (formElement) {
      editData = this.grid.editModule.getCurrentEditedData(formElement, {});
    }
    return editData;
  }

  // Construct edit data from from one field or all fields
  // Include non visible fields?  
  public dataGet(field: string = null): any {
    var data: any = this.ej2Module().getCurrentEditedData(this.formElement(), field ? field : {});
    return data;
  }

  private onCommandPre(event: UiCommandEvent) {
    var done: boolean = false;
    switch (event.source) {
      case UiCommandSourceEnum.ActionBegin:
        switch (event.actionType) {
          case UiActionTypeEnum.Delete:
            this.onDeleteActionBeginPre(event.args);
            break;
          case UiActionTypeEnum.BeginEdit:
            this.onBeginEditActionBeginPre(event.args);
            break;
          case UiActionTypeEnum.Add:
            this.onAddActionBeginPre(event.args);
            break;
          case UiActionTypeEnum.Cancel:
            this.onCancelActionBeginPre(event.args);
            break;
          case UiActionTypeEnum.Save:
            this.onSaveActionBeginPre(event.args);
            break;
        }
        break;
      case UiCommandSourceEnum.ActionComplete:
        switch (event.actionType) {
          case UiActionTypeEnum.BeginEdit:
            this.onBeginEditActionCompletePre(event.args);
            break;
          case UiActionTypeEnum.Add:
            this.onAddActionCompletePre(event.args);
            break;
          case UiActionTypeEnum.Save:
            this.onSaveActionCompletePre(event.args);
            break;
          case UiActionTypeEnum.Cancel:
            this.onCancelActionCompletePre(event.args);
            //event.args.cancel = true; // avoid further keyboard cancel
            break;
        }
        break;
      default:
        switch (event.commandId) {
          case uiCmdEditStart.id:
            if (this.canEditStart()) {
              done = this.doEditStart();
            }
            break;
          case uiCmdEditCancel.id:
            done = this.onCmdEditCancel(event);
            break;
          case uiCmdEditModeTooggle.id:
            done = this.doEditModeToggle();
            break;
        }
        break;
    }
    if (done) {
      event.args.cancel = true;
    }
  }

  private onCommandPost(cmd: UiCommandEvent) {
    if (cmd.source == UiCommandSourceEnum.ActionBegin) {
      switch (cmd.actionType) {
        case UiActionTypeEnum.BeginEdit:
          this.onBeginEditActionBeginPost(cmd.args);
          break;
        case UiActionTypeEnum.Add:
          this.onAddActionBeginPost(cmd.args);
          break;
      }
    }
  }

  // Begin delete
  private deleteConfirmed: boolean = false
  private onDeleteActionBeginPre(args: any) {
    if (!this.deleteConfirmed) {
      if (this.meta.viewMetadata?.confirmDelete !== false) {
        var edit: Edit = this.grid?.editModule;
        if (edit) {
          var content: string = '';
          if (args.data?.length > 0) {
            args.data.forEach((data: any) => {
              var title = this.title(data, false);
              if (title?.length > 0) {
                content += '<li>' + title + '</li>';
              }
            });
          }
          if (content.length > 0) {
            content = '<ul>' + content + '</ul>';
          }
          content = this.getContentDiv(edit, 'ConfirmDelete') + content;
          this.dialogService.confirmPrompt(content).then(ok => {
            if (ok) {
              this.deleteConfirmed = true;
              edit.deleteRecord(args.rowData);
            }
          });
        }
        args.cancel = true;
      }
    }
    this.deleteConfirmed = false;
  }

  private getContentH3(edit: Edit, id: string): string {
    var contentDiv = '<h3>' + (<any>edit).l10n?.getConstant(id) + '</h2>'
    return contentDiv;
  }

  private getContentDiv(edit: Edit, id: string): string {
    var contentDiv = '<div>' + (<any>edit).l10n?.getConstant(id) + '</div>'
    return contentDiv;
  }

  private showDialog(content: string, dialog: any) {
    if (dialog) {
      dialog.content = content;
      dialog.dataBind();
      dialog.show();
    }
  }

  // Begin edit
  private onBeginEditActionBeginPre(args: any) {
    if (document.activeElement && document.activeElement.tagName == "INPUT") {
      args.cancel = true; // cancel edit if input field is active (search bar etc.)
      return;
    }
    this.editInitPre(args, false);
  }

  private onBeginEditActionBeginPost(args: any) {
    this.editInitPost(args, false);
  }

  private onBeginEditActionCompletePre(args: any) {
    this.onEditAddActionCompletePre(args, false)
  }

  // Add
  private onAddActionBeginPre(args: any) {
    this.editInitPre(args, true);
  }

  private onAddActionCompletePre(args: any) {
    this.editedDataOrig = args.rowData;
    this.onEditAddActionCompletePre(args, true);
  }

  private onAddActionBeginPost(args: any) {
    this.editInitPost(args, true);
  }

  // Edit+Add
  private onEditAddActionCompletePre(args: any, isAdd: boolean) {
    if (args.form) {
      args.form.autocomplete = 'off';
    }
    
    this.editedData = args.rowData;
    this.dialogInit(args.dialog, args.rowData, isAdd);
    this.fieldGroupSetup(args.dialog);
  }

  // On Cancel
  private onCancelActionBeginPre(args: any) {
    this.dialogFieldsResetVisible();
    this.dialogFieldsResetAllowEdit();
  }

  private onCancelActionCompletePre(args: any) {
    this.resetEditOriginalData();
  }

  public canEditStart(): boolean {
    var ok: boolean = true;
    if (this.grid.filterModule) {
      var m: any = this.grid.filterModule;
      if (m.fltrDlgDetails != null) {
        ok = !m.fltrDlgDetails.isOpen;
      }
    }
    return ok;
  }

  public doEditStart(): boolean {
    var ok: boolean = false;

    if (this.grid.editSettings.allowEditing && !this.grid.isEdit && this.grid.editModule) {
      this.grid.editModule.startEdit();
      ok = true;
    }

    return ok;
  }

  public doEditModeToggle(): boolean {
    var ok: boolean = false;
    if (this.grid && this.grid.editSettings) {
      this.selection.rowSelectedKeep();
      switch (this.grid.editSettings.mode) {
        case 'Normal':
          this.grid.editSettings.mode = 'Dialog';
          this.grid.editSettings.allowNextRowEdit = false;
          break;
        case 'Dialog':
          if (this.grid.enableAdaptiveUI) {
            if (this.grid.rowRenderingMode == 'Horizontal') {
              this.grid.rowRenderingMode = 'Vertical';
              this.grid.refreshColumns();
            }
            else {
              this.grid.rowRenderingMode = 'Horizontal';
              this.grid.enableAdaptiveUI = false;
              this.grid.editSettings.mode = 'Batch';
              this.grid.editSettings.allowNextRowEdit = true;
            }
          }
          else {
            this.grid.enableAdaptiveUI = true;
          }
          break;
        case 'Batch':
          this.grid.editSettings.mode = 'Normal';
          this.grid.editSettings.allowNextRowEdit = false;
          break;
      }
      var infoStr = "Edit mode: " + this.grid.editSettings.mode + (this.grid.enableAdaptiveUI ? " (Adaptive " + this.grid.rowRenderingMode + ")" : "");
      this.dialogService.snackBar(infoStr);
      ok = true;
    }
    return ok;
  }

  // Edit handling by field name
  // Looks up input and re-directs to input methods
  public fieldValueGet(field: string): any {
    var col: Column = this.fieldColumn(field);
    var input: HTMLInputElement = this.inputUid(col?.uid);
    return this.inputValueGet(input, col, this.editedData);
  }

  public fieldValueSet(field: string, value: any) {
    var input: HTMLInputElement = this.fieldInput(field);
    if (input) {
      this.inputValueSet(input, value);
    }
  }

  public fieldDisable(field: string, disable: boolean) {
    var input: HTMLInputElement = this.fieldInput(field);
    if (input) {
      this.inputDisable(input, disable);
    }
  }

  public fieldMetadata(field: string): FieldMetadata {
    return this.meta.fieldMetaGet(field);
  }

  public fieldColumn(field: string): Column {
    return this.grid.getColumnByField(field);
  }

  public fieldColumnUid(field: string): string {
    return (this.fieldColumn(field))?.uid;
  }

  // Lookup input by field name
  public fieldInput(field: string): HTMLInputElement {
    return this.inputUid(this.fieldColumnUid(field));
  }

  // Lookup input by column uid 
  public inputUid(uid: string): HTMLInputElement {
    var input: any = null;
    if (uid) {
      var form: HTMLFormElement = this.formElement();
      if (form) {
        var inputs = [].slice.call(form.getElementsByClassName('e-field'));
        for (var i: number = 0, len = inputs.length; i < len; i++) {
          var inputCheck: HTMLInputElement = inputs[i] as HTMLInputElement;
          var inputUid: string = inputCheck.getAttribute('e-mappinguid');
          if (inputUid && inputUid == uid) {
            input = inputCheck;
            break;
          }
        }
      }
    }
    return input;
  }

  // Edit handling by input
  public inputValueGet(input: HTMLInputElement, col: Column, editedData: any): any {
    if (input) {
      var value = (<any>this.ej2Module()).getValue(col, input, this.editedData);
      if (col.type === 'string') {
        value = this.grid.sanitize(value);
      }
      return value;
    }
    return null;
  }

  public inputDropDownListGet(input: HTMLInputElement): DropDownList {
    var ddl: DropDownList = null;
    if (input.classList.contains('e-dropdownlist')) {
      ddl = getComponent(input, 'dropdownlist');
    }
    return ddl;
  }

  public inputValueSet(input: HTMLInputElement, value: any) {
    //DataUtil.setValue(col_1.field, value, editedData);
    if (input) {
      switch (input.type) {
        case 'checkbox':
          var checkBox: CheckBox = getComponent(input, 'checkbox');
          if (checkBox) {
            checkBox.checked = value;
          }
          else {
            input.checked = value;
          }
          break;
        default:
          if (input.classList.contains('e-dropdownlist')) {
            var ddl: DropDownList = getComponent(input, 'dropdownlist');
            if (ddl) {
              ddl.value = value;
            }
          }
          else if (input.classList.contains('e-numerictextbox')) {
            var ntb: any = getComponent(input, 'numerictextbox');
            if (ntb) {
              ntb.value = value;
            }
          }
          else if (input.classList.contains('e-textbox')) {
            var tb: any = getComponent(input, 'textbox');
            if (tb) {
              tb.value = value;
            }
          }
          else {
            input.value = value;   // TODO: Formatting? (Number+Date)
          }
          if (this.inputHasFocus(input)) {
            input.blur(); // Run any validation
            this.grid?.editModule?.editFormValidate();
            input.focus();
          }
          else {
            this.validate(/*input.id*/);
          }
      }
    }
  }

  public validate(fieldName: string = null) {
    setTimeout(() => {
      if (fieldName) {
        this.grid?.editModule?.formObj.validate(fieldName);
      }
      else {
        this.grid?.editModule?.editFormValidate();
      }
    },1);
  }

  public inputHasFocus(input: HTMLInputElement): boolean {
    var activeElement = document.activeElement;
    return (input && activeElement === input);
  }

  public inputGetComponent(input: HTMLInputElement, type: string = null): any {
    if (input) {
      if (type?.length > 0) {
        return getComponent(input, type);
      }
      else {
        var inputAny: any = input;
        var ej2: any[] = inputAny.ej2_instances;
        if (ej2?.length > 0) {
          var component: any = ej2[0];
          return component;
        }
      }
    }
    return null;
  }

  public inputIsDisabled(input: HTMLInputElement): boolean {
    var disabled: boolean;

    if (input) {
      var done: boolean = false;
      switch (input.type) {
        case 'checkbox':
          var checkBox: CheckBox = this.inputGetComponent(input, 'checkbox');
          if (checkBox) {
            disabled = checkBox.disabled;
            done = true;
          }
          break;
        default:
          var component: any = this.inputGetComponent(input);
          if (component) {
            disabled = !component.enabled;
            done = true;
          }
      }
      if (!done) {
        var parent: HTMLElement = <HTMLElement>input.parentNode;
        disabled = (parent.classList.contains("e-disabled"));
      }
    }
    return disabled;
  }

  public inputDisable(input: HTMLInputElement, disable: boolean) {
    if (input) {
      var done: boolean = false;
      switch (input.type) {
        case 'checkbox':
          var checkBox: CheckBox = getComponent(input, 'checkbox');
          if (checkBox) {
            checkBox.disabled = disable;
          }
          else {
            input.disabled = disable;
          }
          done = true;
          break;
        default:
          var component: any = this.inputGetComponent(input);
          if (component) {
            component.enabled = !disable;
            done = true;
          }
      }
      //if (!done) {
      var parent: HTMLElement = <HTMLElement>input.parentNode;
      if (disable) {
        if (!parent.classList.contains("e-disabled")) {
          parent.classList.add("e-disabled");
        }
      }
      else {
        if (parent.classList.contains("e-disabled")) {
          parent.classList.remove("e-disabled");
        }
      }
      //}
    }
  }

  // Dialog edit + virtual scroll etc. enabled requires reset of "previousData".
  // It supplies incorrect old values for non visible dialog fields
  // Resetting will enforce reinit in NormalEdit.prototype.startEdit 
  private resetEditOriginalData() {
    (<any>this.grid.editModule.editModule).previousData = null;
  }

  private onSaveActionBeginPre(args: any) {
    this.dialogFieldsResetVisible();
    this.dialogFieldsResetAllowEdit();

    args.data.uuid = this.uuid;  // Send dialog edit session uuid to server in the dto

    if (args.action == "insert") {
      if (this.grid.pageSettings.currentPage !== 1 && this.grid.editSettings.newRowPosition === 'Top') {
        args.index = (this.grid.pageSettings.currentPage * this.grid.pageSettings.pageSize) - this.grid.pageSettings.pageSize;
      } else if (this.grid.editSettings.newRowPosition === 'Bottom') {
        args.index = (this.grid.pageSettings.currentPage * this.grid.pageSettings.pageSize) - 1;
      }
    }
    else {
      this.validateUpdatePrimaryKey(args);
    }
  }

  private validateUpdatePrimaryKey(args: any) {
    if (this.editAcceptUpdatePrimaryKey) {
      this.editAcceptUpdatePrimaryKey = false;
      return;
    }
    if (args.primaryKey?.length > 0 && args.primaryKeyValue?.length > 0) {
      args.primaryKey.forEach((pk: string, index: number) => {
        var pkValue = args.primaryKeyValue[index];
        var pkValueData = args.data[pk];
        if (pkValueData != pkValue) {
          this.confirmUpdatePrimaryKey(args, pk, pkValue, pkValueData);
        }
      });
    }
  }

  private confirmUpdatePrimaryKey(args: any, pk: string, pkValue: any, pkValueData: any) {
    args.cancel = true; // Resubmit after confirmation
    var meta = this.meta.fieldMetaGet(pk);
    var msg: string = 'Nøglefelt ' + meta.text +
      ' opdateres fra ' + pkValue + ' til ' + pkValueData + '.' +
      ' Er du sikker?';
    this.dialogService.confirmPrompt(msg, 'Opdatering').then(ok => {
      if (ok) {
        this.editAcceptUpdatePrimaryKey = true;
        this.grid.endEdit();
      }
    });
  }

  private onSaveActionCompletePre(args: any) {
    this.resetEditOriginalData();
    this.grid.focusModule.restoreFocus();
  }

  private editInitPre(args: any, isAdd: boolean) {
    this.editedData = args.rowData;
    this.uuid = uuidv4();

    if (this.grid.editSettings.mode == "Dialog") {
      if (!isAdd) {
        this.dialogInitFixArgsData(args);
      }
      if (this.data.fieldMetadata) {
        this.dialogFieldsVisibleAndAllowEditPrepare(isAdd);
      }
      if (this.design.useEditTemplate) {
        args.isCustomFormValidation = true; // Disable convertWidget method - rely on template for inputs
      }
      this.editTemplateService.setup(isAdd, this.grid.getColumns(), this.meta.tabMetadata, this.meta.fieldGroupMetadata, this.meta.fieldMetadata);
    }
  }


  private editInitPost(args: any, isAdd: boolean) {
    if (this.grid.editSettings.mode == "Dialog") {
      if (this.data.fieldMetadata) {
        this.dialogFieldsVisibleAndAllowEditSetup(isAdd);
      }
    }
  }

  private dialogInitFixArgsData(args: any) {
    if (args.row) {
      var tr: HTMLTableRowElement = args.row;
      var uid: string = tr.getAttribute('data-uid');
      if (uid) {
        var rowObj = this.grid.getRowObjectFromUID(uid);
        args.rowData = rowObj.data;
        args.foreignKeyData = rowObj.foreignKeyData;
      }
    }
  }

  private title(data: any, isAdd: boolean): string {
    var title: string = this.data ? this.data.viewTextSingular(true) : "Post";
    this.data ? this.data.viewTextSingular(true) : "Post";
    if (isAdd) {
      title = 'Ny ' + title.toLowerCase();
    }
    else {
      if (data && this.data) {
        var dataTitle = this.data.viewDataTitle(data);
        title += dataTitle.length > 0 ? ': ' + dataTitle : '';
      }
    }
    return title;
  }

  private dialogInit(dialog: Dialog, data: any, isAdd: boolean) {
    if (this.grid.editSettings.mode == "Dialog" && dialog) {
      this.dialogHeaderInit(dialog, data, isAdd);
      if (!this.grid.enableAdaptiveUI) {
        dialog.enableResize = true;
        dialog.allowDragging = true;
      }
      dialog.closeOnEscape = false; // Remapped to uiCmdEditCancel
      this.dialogEventHandlerBind(dialog);
    }
  }

  private dialogHeaderInit(dialog: Dialog, data: any, isAdd: boolean) {
    var title: string = this.title(data, isAdd);
    if (dialog.header) {
      if (typeof dialog.header == 'string') {
        dialog.header = title;
      }
      else {
        var header: HTMLElement = <HTMLElement>dialog.header;
        if (header?.firstElementChild) {
          header.firstElementChild.innerHTML = title;
        }
        else {
          header.innerHTML = title;
        }
      }
    }
  }

  private dialogFieldsVisibleAndAllowEditPrepare(isAdd: boolean) {
    this.dialogFieldsVisibleSave = [];
    this.dialogFieldsVisibleEdit = [];
    this.dialogFieldsAllowEditSave = [];
    this.dialogFieldsAllowEditEdit = [];

    this.data.fieldMetadata.forEach(meta => {
      if (meta.currentRowAllowEdit !== undefined) {
        meta.currentRowAllowEdit = undefined;
      }
      if (meta.currentRowVisible !== undefined) {
        meta.currentRowVisible = undefined;
      }
    });
  }

  private dialogFieldsVisibleAndAllowEditSetup(isAdd: boolean) {
    if (this.grid?.columns) {
      this.grid.columns.forEach((column: ColumnModel) => {
        if (column.field && this.data?.fieldMetadata) {
          var fieldMetadata: FieldMetadata = this.data.fieldMetadata.find(f => f.name == column.field);
          this.dialogFieldSetVisible(column, isAdd);
          this.dialogFieldSetAllowEdit(column, fieldMetadata, isAdd);
        }
      });
    }
    else {
      console.log("dialogFieldsVisibleAndAllowEditSetup: No grid columns");
    }
  }

  private dialogFieldSetVisible(c: ColumnModel, isAdd: boolean) {
    if (c.visible || c.visible == null) {
      this.dialogFieldsVisibleSave.push(c.field);
    }

    c.visible = this.editMetaService.isFieldVisible(c, isAdd);

    if (c.visible !== false) {
      this.dialogFieldsVisibleEdit.push(c.field);  // Used in field group setup
    }
  }

  private dialogFieldSetAllowEdit(c: ColumnModel, fieldMetadata: FieldMetadata, isAdd: Boolean) {
    if (c.allowEditing || c.allowEditing == null) {
      this.dialogFieldsAllowEditSave.push(c.field);
    }

    if (fieldMetadata) {

      if (isAdd && fieldMetadata.allowEditOnCreate !== undefined) {
        c.allowEditing = fieldMetadata.allowEditOnCreate;
      }
      else if (fieldMetadata.allowEdit !== undefined) {
        c.allowEditing = fieldMetadata.allowEdit;
      };
      if (c.allowEditing && fieldMetadata.currentRowAllowEdit !== undefined) {
        c.allowEditing = fieldMetadata.currentRowAllowEdit;
      }
    }

    if (c.allowEditing || c.allowEditing == null) {
      this.dialogFieldsAllowEditEdit.push(c.field);  // Used in field group setup
    }
  }

  private dialogFieldsResetVisible() {
    if (this.dialogFieldsVisibleSave && this.dialogFieldsVisibleSave.length > 0) {
      this.grid.columns.forEach((c: ColumnModel) => {
        if (c.field) {
          c.visible = (this.dialogFieldsVisibleSave.indexOf(c.field) >= 0);
        }
      });
      this.dialogFieldsVisibleSave = null;
    }
  }

  private dialogFieldsResetAllowEdit() {
    if (this.dialogFieldsAllowEditSave && this.dialogFieldsAllowEditSave.length > 0) {
      this.grid.columns.forEach((c: ColumnModel) => {
        if (c.field) {
          c.allowEditing = (this.dialogFieldsAllowEditSave.indexOf(c.field) >= 0);
        }
      });
      this.dialogFieldsAllowEditSave = null;
    }
  }

  private fieldGroupSetup(dialog: Dialog) {
    if (this.grid.editSettings.mode == "Dialog" && dialog && this.meta.fieldGroupMetadata) {

      if (dialog.content && dialog.content instanceof HTMLElement) {
        var form: HTMLFormElement = dialog.content.querySelector('.e-gridform');
        if (form) {
          var formEj2: any = (<any>form).ej2_instances[0];
          var rules = formEj2.rules; // Save default valdation.
          formEj2.rules = {}; // Disable default valdation while layout changing

          this.dialogFieldGroupSetup(dialog, form);

          formEj2.rules = rules; // Enable default valdation after layout changes
        }
      }
    }
  }

  /*********************
   * Keyboard shortcuts
   ********************/

  public onCmdEditCancel(event: UiCommandEvent): boolean {
    this.grid.editModule.closeEdit();
    return true;
  }


  private activeDialog: Dialog;
  private dialogEventHandlerBind(dialog: Dialog) {
    this.activeDialog = dialog;
    EventHandler.add(dialog.element, 'keydown', this.dialogKeyDown, this);
  };

  private dialogEventHandlerUnbind(dialog: Dialog) {
    EventHandler.remove(dialog.element, 'keydown', this.dialogKeyDown);
    this.activeDialog = null;
  }

  public dialogKeyDown(e: KeyboardEvent) {
    if (this.activeDialog) {
      //if ((this.activeDialog as any)?.isDisabled()) {
      //  return;
      //}
      var stop: boolean = false;
      this.dialogKeyboardShortcuts.forEach(shortcut => {
        if (this.command.keyboardShortcutMatch(e, shortcut)) {
          if (shortcut.cmd != uiCmdEditCancel || this.dialogValidateKeyboardCancel(e))
            var cancel: boolean = this.command.cmdDispatch(UiCommandSourceEnum.KeyboardShortcut, shortcut.cmd.id, e, null, UiActionTypeEnum.KeyboardShortcut, null);
          if (cancel || shortcut.allowsPreventDefault) {
            stop = true;
          }
        }
      });
      if (stop) {
        e.stopPropagation();
        e.preventDefault();
      }
    }
  }

  private dialogValidateKeyboardCancel(e: KeyboardEvent): boolean {
    var query = document.querySelector('.e-popup-open:not(.e-dialog)');
    // 'document.querySelector' is used to find the elements rendered based on body
    if ((query != null) && !query.classList.contains('e-toolbar-pop')) {
      return false;
    }
    else {
      (this.activeDialog as any).dlgClosedBy = 'escape';
      return true;
    }
  }

  /*
  private editDialogShortCutEvent(dialog: Dialog) {
{}    
  if (event.keyCode === 9) {
      if (this.isModal) {
          var buttonObj = void 0;
          if (!isNullOrUndefined(this.btnObj)) {
              buttonObj = this.btnObj[this.btnObj.length - 1];
          }
          if ((isNullOrUndefined(this.btnObj)) && (!isNullOrUndefined(this.ftrTemplateContent))) {
              buttonObj = this.getFocusElement(this.ftrTemplateContent);
          }
          if (isNullOrUndefined(this.btnObj) && isNullOrUndefined(this.ftrTemplateContent) && !isNullOrUndefined(this.contentEle)) {
              buttonObj = this.getFocusElement(this.contentEle);
          }
          if (!isNullOrUndefined(buttonObj) && document.activeElement === buttonObj.element && !event.shiftKey) {
              event.preventDefault();
              this.focusableElements(this.element).focus();
          }
          if (document.activeElement === this.focusableElements(this.element) && event.shiftKey) {
              event.preventDefault();
              if (!isNullOrUndefined(buttonObj)) {
                  buttonObj.element.focus();
              }
          }
      }
  }
  var element = document.activeElement;
  var isTagName = (['input', 'textarea'].indexOf(element.tagName.toLowerCase()) > -1);
  var isContentEdit = false;
  if (!isTagName) {
      isContentEdit = element.hasAttribute('contenteditable') && element.getAttribute('contenteditable') === 'true';
  }
  if (event.keyCode === 27 && this.closeOnEscape) {
      this.dlgClosedBy = DLG_ESCAPE_CLOSED;
      var query = document.querySelector('.e-popup-open:not(.e-dialog)');
      // 'document.querySelector' is used to find the elements rendered based on body
      if (!(!isNullOrUndefined(query) && !query.classList.contains('e-toolbar-pop'))) {
          this.hide(event);
      }
  }
*/


  /*
  diag if (event.keyCode === 27 && this.closeOnEscape) {
  this.dlgClosedBy = DLG_ESCAPE_CLOSED;
  var query = document.querySelector('.e-popup-open:not(.e-dialog)');
  // 'document.querySelector' is used to find the elements rendered based on body
  if (!(!isNullOrUndefined(query) && !query.classList.contains('e-toolbar-pop'))) {
      this.hide(event);
  }
  */

  private firstInputCtrl: HTMLElement = null;
  private dialogFieldGroupSetup(dialog: Dialog, form: HTMLFormElement) {
    // By default all edit fields are in one table.
    // Change layout to field groups (and tabs if any)
    var table: HTMLTableElement = form.querySelector('.e-table');
    if (table) {
      this.firstInputCtrl = null;
      var tbody = table.querySelector('tbody');
      if (tbody) {
        if (this.dialogFieldGroupSetupTable(dialog, form, tbody)) {
          form.removeChild(table); // Remove existing empty? table
        };
      }
      if (this.firstInputCtrl) {
        if (this.firstInputCtrl instanceof HTMLInputElement) {
          this.focusInput(this.firstInputCtrl);
        this.firstInputCtrl = null;
        }
      }
    }
  }

  private focusInput(input: HTMLInputElement) {
    if (input) {
      this.getFocusElement(input)?.focus();
    }
  }

  private getFocusElement(input: HTMLInputElement) : HTMLElement {
    if (input) {
      var ddlcontainer : HTMLElement = CerDropDownList.getDdlContainerDivFromInput(input);
      if (ddlcontainer) {
        return ddlcontainer;
      }
    }
    return input;
  }

  
  private dialogFieldGroupSetupTable(dialog: Dialog, form: HTMLFormElement, tbodyStd: HTMLTableSectionElement): boolean {
    var done: boolean = false;

    var i: number = 0;


    form.classList.add('e-grid-form-field-group');

    if (this.meta.tabMetadata) {
      /*
            var tab: HTMLDivElement = document.createElement('div');
            form.appendChild(tab);
            tab.classList.add('e-grid-field-tab');
            this.fieldTabMetadata.forEach(tabMeta => {
              var tabButton: HTMLButtonElement = document.createElement('button');
              tab.appendChild(tabButton);
              tabButton.classList.add('e-btn');
              tabButton.classList.add('e-btn-secondary');
              tabButton.classList.add('e-btn-small');
              tabButton.classList.add('e-btn-tab');
              tabButton.innerText = tabMeta.text;
              tabButton.addEventListener('click', (args) => {
               // this.dialogFieldGroupsFormTabClick(tabButton, tabMeta);
              });
            });
      */
    }

    var tbodyFallback: HTMLTableSectionElement = null;
    var prevGroup: FieldGroupMetadata = null;
    var columnDiv: HTMLDivElement = null;
    var widthTotal: number = 100;
    this.meta.fieldGroupMetadata.filter(g => this.isFieldGroupVisible(g)).forEach(group => {

      var columnWidth: number = group.width ?? 160;
      var newColumDiv: HTMLDivElement = this.dialogGroupSetupColumn(group, prevGroup, columnWidth, form);
      var newColumn: boolean = (newColumDiv != null);
      if (newColumn) {
        columnDiv = newColumDiv;
        widthTotal += columnWidth;
      }
      
      var tbody: HTMLTableSectionElement = this.dialogGroupSetupGroup(group, columnDiv, newColumn);
      tbodyFallback = tbody;

      group.fields.forEach(fieldName => {
        this.dialogGroupSetupField(fieldName, tbodyStd, tbody);
      });
      prevGroup = group;
      done = true;
    });
    
    if (tbodyFallback && tbodyStd.rows.length > 0) {
      for (i = 0; i < tbodyStd.rows.length; i++) {
        var row = tbodyStd.rows[i];
        tbodyFallback.appendChild(row); /* Move to fallback group (TODO: Required) */
      }
    }

    if (widthTotal > 0) {
      dialog.width = (widthTotal + 100) + "px";
    }
    return done;
  }

  private dialogGroupSetupColumn(group: FieldGroupMetadata, prevGroup: FieldGroupMetadata, columnWidth: number, form: HTMLFormElement): HTMLDivElement {
    var createNewColumn: boolean = ((prevGroup == null || prevGroup.idx != group.idx) && !this.grid.enableAdaptiveUI);
    if (createNewColumn) {
      var columnDiv: HTMLDivElement = document.createElement('div');
      columnDiv.classList.add('e-grid-field-group');
      form.appendChild(columnDiv);
      columnDiv.style.flexBasis = columnWidth + "px";
      return columnDiv;
    }
    return null;
  }

  private dialogGroupSetupGroup(group: FieldGroupMetadata, columnDiv: HTMLDivElement, newColumn: boolean): HTMLTableSectionElement {
    var table: HTMLTableElement = document.createElement('table');
    columnDiv.appendChild(table);
    var tbody: HTMLTableSectionElement = document.createElement('tbody');
    table.appendChild(tbody);
    var row: HTMLTableRowElement = document.createElement('tr');
    tbody.appendChild(row);
    var cell: HTMLTableCellElement = document.createElement('td');
    row.appendChild(cell);
    var h3 = document.createElement('h3');
    if (!newColumn) {
      h3.classList.add('e-grid-field-group-header-more');
    }
    cell.appendChild(h3);

    var pre = document.createElement('pre');
    h3.appendChild(pre);
    pre.innerText = group.text;

    return tbody;
  }

  private dialogGroupSetupField(fieldName: string, tbodyStd: HTMLTableSectionElement, tbody: HTMLTableSectionElement) {
    var isVisible: boolean = (this.dialogFieldsVisibleEdit.indexOf(fieldName) >= 0);
    if (isVisible) {
      var input: HTMLInputElement = this.dialogTableInputFind(tbodyStd, fieldName);
      if (input) {
        this.dialogFieldGroupSetupFieldMove(input,tbody);
        if (!this.firstInputCtrl) {
          if (!this.inputIsDisabled(input)) {
            this.firstInputCtrl = input;
          }
        }
      }
    }
  }

  private dialogFieldGroupSetupFieldMove(input: HTMLInputElement, tbody: HTMLTableSectionElement) {
    var isRequired: boolean = input.required;
    if (isRequired) {
      input.required = false; // Ignore required validator
    }
    var row: HTMLTableRowElement = input.closest('tr');
    if (row) {
      tbody.appendChild(row);
    }
    if (isRequired) {
      input.required = true;
    }
  }

  private isFieldGroupVisible(group: FieldGroupMetadata): boolean {
    var groupVisible: boolean = false;

    group?.fields?.forEach(name => {

      var field = this.fieldColumn(name);
      if (field && field.visible !== false) {
        groupVisible = true;
      }
    });

    return groupVisible;
  }

  public dialogTableInputFind(tbodyStd: HTMLElement, fieldName: string): HTMLInputElement {
    var id = '#' + this.grid.element.id + fieldName.replaceAll('.', '___');
    var input: HTMLInputElement = tbodyStd.querySelector(id) as HTMLInputElement;
    return input;
  }
}

function addRemoveClass(element: Element, cls: string, add: boolean) {
  if (add) {
    if (!element.classList.contains(cls)) {
      element.classList.add(cls);
    }
  }
  else {
    if (!element.classList.contains(cls)) {
      element.classList.add(cls);
    }
  }
}

function addRemoveActiveClasses(elements: HTMLCollectionOf<Element>, classes: string[], add: boolean) {
  for (var i = 0; i < elements.length; i++) {
    var element = elements[i];
    for (var j = 0; j < classes.length; j++) {
      addRemoveClass(element, classes[j], add);

      if (add) {
        element.setAttribute('aria-selected', 'true');
      }
      else {
        element.removeAttribute('aria-selected');
      }
    }
  }
}