// Angular
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

// SyncFusion
import { Column } from '@syncfusion/ej2-angular-grids';

// Cerium
import { FieldGroupMetadata, FieldMetadata, TabMetadata } from '../cer-data/cer-data.service';
import { CerEditMetaService } from './cer-edit-meta.service';

export class CerEditGroupSetup {
  group: FieldGroupMetadata;
  columns: Column[];
};

export class CerEditTabSetup {
  public tab: TabMetadata;
  public groups: CerEditGroupSetup[];
}

export class CerEditTemplateSetup {
  public tabs: CerEditTabSetup[];
}

@Injectable()
export class CerEditTemplateService {

  public templateSetup$: Subject<CerEditTemplateSetup> = new Subject<CerEditTemplateSetup>();  // Tab group fields visible populated for edit template
  
  constructor(private editMetaService: CerEditMetaService) { }

  public setup(isAdd: boolean, allColumns: Column[], tabs: TabMetadata[], groups: FieldGroupMetadata[], fields: FieldMetadata[]) {
    this.templateSetup$.next(this.setupGet(isAdd, allColumns ,tabs, groups, fields));
  }

  private setupGet(isAdd: boolean, allColumns: Column[], tabs: TabMetadata[], groups: FieldGroupMetadata[], fields: FieldMetadata[]): CerEditTemplateSetup {
    var setup = new CerEditTemplateSetup();
    setup.tabs = [];
    var visibleColumns: Column[] = allColumns.filter(c => this.editMetaService.isFieldVisible(c, isAdd));
    if (tabs?.length > 0) {
      tabs.forEach(tab => this.setupTab(setup.tabs, tab, groups, visibleColumns, fields));
    }
    if (setup.tabs.length == 0) {
      this.setupTab(setup.tabs, null, groups, visibleColumns, fields); // No tabs - everything in just one null tab
    }
    return setup;
  }

  private setupTab(setupTabs : CerEditTabSetup[], tab: TabMetadata, groups: FieldGroupMetadata[], columns: Column[], fields: FieldMetadata[]) {
    var tabSetup: CerEditTabSetup = new CerEditTabSetup();
    tabSetup.tab = tab;
    tabSetup.groups = [];
    if (groups?.length > 0 && tab?.idx != null) {
      groups.filter(g => g.tabIdx == tab.idx).forEach(group => {
        this.setupGroup(tabSetup.groups, group, columns, fields);
      })
    }
    if (tabSetup.groups.length == 0 && !tab) {
        this.setupGroup(tabSetup.groups, null, columns, fields); // No tabs - everything in just one null group
    }
    if (tabSetup.groups.length > 0)
    {
       setupTabs.push(tabSetup);
    }
  }

  private setupGroup(setupGroups: CerEditGroupSetup[], group: FieldGroupMetadata, columns: Column[], fields: FieldMetadata[]) {
    var groupSetup: CerEditGroupSetup = new CerEditGroupSetup();
    groupSetup.group = group;
    groupSetup.columns = [];
    if (group) {
      columns.filter(c => group.fields.includes(c.field)).forEach(c => groupSetup.columns.push(c));
    }
    else {
      columns.forEach(c => groupSetup.columns.push(c));
    }
    if (groupSetup.columns.length > 0)
    {
      setupGroups.push(groupSetup);
    }
  }

}