import { CerAppFormModelAdapter } from '../../../platform/models/cer-app-form-model-adapter';
import { CustInvoiceService } from '../cust-invoice.service';
import { UiCommandEvent } from 'src/cer/cer-grid/cer-grid-command.service';

export class CustInvoiceFormAdapter extends CerAppFormModelAdapter {

  public svc : CustInvoiceService = null;

  override init() {
    this.svc = this.injector.get(CustInvoiceService);

    var isDraft: boolean = this.routeParams.filter(p => p == '$status=Draft').length > 0;
    var isCredit: boolean = this.routeParams.filter(p => p == '$payment=Credit').length > 0;
    this.viewMetadata = CustInvoiceService.tableView(this.routeParams, isDraft);
    this.toolbarCommands = CustInvoiceService.tableToolbar(isDraft, isCredit);
    this.fieldMetadata = CustInvoiceService.tableFields(isDraft);
    this.detailPaneVertical = true;
    this.detailPaneSizePct = 50;
    this.detailViewMetadata = CustInvoiceService.lineView(isDraft);
    this.detailFieldMetadata = CustInvoiceService.lineFields(isDraft);
    this.detailToolbarCommands = CustInvoiceService.lineToolbar(isDraft);
  }

  override onCommand(event: UiCommandEvent) {
    this.svc.onCommand(event);
  }

  override onDetailCommand(event: UiCommandEvent) {
    this.svc.onCommand(event);
  }
}
