import { FieldGroupMetadata, FieldMetadata, FieldWidthEnum, ViewMetadata } from "src/cer/cer-data/cer-data.service";
import { uiCmdSeparator, UiCommand, UiCommandEvent } from "src/cer/cer-grid/cer-grid-command.service";

export const cmdCustInvoiceDocument: UiCommand = { id: 'cmdCustInvoiceDocument', text: 'Dokument', iconCss: 'document' };

export const TableViewMetadata: ViewMetadata = {
  name: 'salgs-fakturaer', dataApiName: 'CustInvoice', text: 'Fakturaer', textSingular: 'Faktura',
  primaryKey: 'num', baseFields: ['id', 'num', 'name', 'history']
};

export const TableFieldMetadata: FieldMetadata[] = [
  { name: 'num', text: 'Nummer', allowEdit: false },
  { name: 'name', text: 'Navn' },
  { name: 'invoiceDate', text: 'Fakturadato', format: 'DateShort' },
  { name: 'invoiceNum', text: 'Fakturanr.' },
  { name: 'settleDate', text: 'Betalt dato', format: 'DateShort' },
  { name: 'formRecognizerDocument', text: 'Formular dokument', format: 'Integer', visible: false },
  { name: 'voucherMessage', text: 'Besked', format: 'Integer', visible: false },
  { name: 'voucher', text: 'Udgiftsbilag', format: 'Integer', visible: false },
  { name: 'voucherNum', text: 'Bilagnr.' },
  { name: 'status', text: 'Bogført', format: 'CheckBox', allowFiltering: false, allowSorting: false },
  { name: 'custNum', text: 'Debitor' },
  { name: 'custEAN', text: 'EAN' },
  { name: 'custVATNum', text: 'CVR' },
  { name: 'custEmail', text: 'E-mail', format: 'Email' },
  { name: 'custPhone', text: 'Telefon', format: 'Phone' },
  { name: 'note', text: 'Kundenotat', format: 'TextArea' },
  { name: 'paymentType', text: 'Betaling' },
  { name: 'paymentReference', text: 'Betalingsref' },
  { name: 'paymentFeeMst', text: 'Betal gebyr', format: 'Amount' },
  { name: 'salesOrder', text: 'Salgsordre' },
  { name: 'salesChannel', text: 'Salgskanal' },
  { name: 'currency', text: 'Valuta' },
  { name: 'amountCurExTax', text: 'Eks. moms', format: 'Amount' }, 
  { name: 'amountCurTax', text: 'Moms', format: 'Amount' },
  { name: 'amountCur', text: 'Fakturabeløb', format: 'Amount' },
  { name: 'amountCurService', text: 'Forsendelse', format: 'Amount' },
  { name: 'companyName', text: 'Fakturanavn' },
  { name: 'address1', text: 'Adresse 1' },
  { name: 'address2', text: 'Adresse 1' },
  { name: 'postalCode', text: 'Postnr.' },
  { name: 'postalDistrict', text: 'By' },
  { name: 'countryCode', text: 'Land' },
  { name: 'createdJobId', text: 'Oprettet job', visible: false },
  { name: 'modifiedJobId', text: 'Rettet job', visible: false },
];

export const LineViewMetadata: ViewMetadata = {
  name: 'salgs-faktura-linjer', dataApiName: 'CustInvoiceLine', text: 'Fakturalinjer', textSingular: 'Fakturalinje',
  primaryKey: 'id', parentPrimaryKey: 'num', parentForeignKey: 'invoice',
  baseFields: ['id', 'history'], titleFields: ['id', 'name']
};

export const LineFieldMetadata: FieldMetadata[] = [
  { name: 'line', text: 'Linje', format: 'Integer', visible: false, orderBy: 'Ascending', orderByPriority: 1 },
  { name: 'invoice', text: 'Faktura', visible: false },
  { name: 'type', text: 'Type', foreignKeyTableName: 'SalesOrderLineType', foreignKeyField: 'id', foreignKeyValue: 'name', width: '80px' },
  { name: 'category', text: 'Kategori', visible: false },
  { name: 'salesOrderLine', text: 'Ordrelinje', visible: false },
  { name: 'product', text: 'Vare' },
  { name: 'name', text: 'Navn' },
  { name: 'qty', text: 'Antal', format: 'Number', footerType: 'Sum' },
  { name: 'price', text: 'Pris', format: 'Amount', footerType: 'Sum' },
  { name: 'amountCurDiscount', text: 'Rabat', format: 'Amount', footerType: 'Sum' },
  { name: 'amountCurExTax', text: 'Eks moms', format: 'Amount', footerType: 'Sum' },
  { name: 'amountCurTax', text: 'Moms', format: 'Amount', footerType: 'Sum' },
  { name: 'amountCur', text: 'Beløb', format: 'Amount', footerType: 'Sum' }
];

export class CustInvoiceView {

  public static tableView(routeParams: string[], isDraft: boolean): ViewMetadata {
    var canEdit = isDraft !== false;
    return Object.assign(
      { dataApiParams: routeParams, allowCreate: canEdit, allowEdit: canEdit, allowDelete: canEdit },
      TableViewMetadata
    );
  }

  public static tableFields(isDraft: boolean): FieldMetadata[] {
    var fields: FieldMetadata[] = TableFieldMetadata.map(f => Object.assign({}, f));

    if (isDraft != null) {
      fields.filter(f => f.name == 'status').forEach(f => {
        f.filterValue = (isDraft ? 0 : 1);
        f.filterOperator = 'equal';
      });
    }

    return fields;
  }

  public static tableToolbar(isDraft: boolean, isCredit: boolean): UiCommand[] {
    var toolbar: UiCommand[] = [];
    if (!isCredit) {
      toolbar.push(cmdCustInvoiceDocument);
    }
    return toolbar;
  }

  public static lineView(isDraft: boolean): ViewMetadata {
    return Object.assign(
      { allowCreate: isDraft, allowEdit: isDraft, allowDelete: isDraft },
      LineViewMetadata
    );
  }

  public static lineFields(isDraft: boolean): FieldMetadata[] {
    var fields: FieldMetadata[] = LineFieldMetadata.map(f => Object.assign({}, f));

    fields.filter(f => f.name == 'status').forEach(f => {
      f.filterValue = (isDraft ? 0 : 1);
      f.filterOperator = 'equal';
    });

    return fields;
  }

  public static lineToolbar(isDraft: boolean): UiCommand[] {
    var toolbar: UiCommand[] = [];
    return toolbar;
  }
}
