import { ChangeEventArgs, DropDownList, dropDownListClasses, FilteringEventArgs, FilterType } from "@syncfusion/ej2-angular-dropdowns";
import { Column } from "@syncfusion/ej2-angular-grids";
import { EventHandler, KeyboardEventArgs } from "@syncfusion/ej2-base";
import { DataManager, Query, Predicate } from '@syncfusion/ej2-data';

import { FieldMetadata } from "src/cer/cer-data/cer-data.service";

export class CerDropDownList extends DropDownList {

  protected fieldMeta: FieldMetadata;
  protected popupOpenOnEmptyFocus: boolean = false;
  public baseQuery: Query = null;

  private autoBuildQuery: boolean = true;
  private filterCompletedText: string;
  private defaultSearchOperator: FilterType = 'StartsWith';
  private filterRunDelay: number;
  private filterRunDelayFilterLengthMin: number = 1;
  private columns: Column[] = [];
  private selectColumnNames: string[] = [];

  protected static getDdlFromInputContainer(currentTarget: Node): CerDropDownList {
    var ddl: CerDropDownList = null;
    if (currentTarget && currentTarget.hasChildNodes()) {
      for (var i: number = 0; i < currentTarget.childNodes.length; ++i) {
        var child: Node = currentTarget.childNodes[i];
        if (child instanceof HTMLInputElement) {
          ddl = CerDropDownList.getDdlFromInput(child);
          return ddl;
        }
        else if (child.hasChildNodes()) {
          ddl = CerDropDownList.getDdlFromInputContainer(child);
          if (ddl) {
            return ddl;
          }
        }
      }
    }
    return ddl;
  }

  protected static getDdlFromInput(input: HTMLInputElement): CerDropDownList {
    var ddl: CerDropDownList = null;
    var ele: any = input;
    for (var i: number = 0; i < ele.ej2_instances.length; ++i) {
      var ej2 = ele.ej2_instances[i];
      if (ej2 instanceof CerDropDownList) {
        ddl = ej2;
        return ddl;
      }
    }
    return ddl;
  }

  public static getDdlContainerDivFromInput(input: HTMLInputElement): HTMLElement {
    var ddl: CerDropDownList = CerDropDownList.getDdlFromInput(input)
    if (ddl) {
      return ddl.inputWrapper?.container;
    }
    return null;
  }

  constructor(options: any) {
    super(options);
    this.popupOpenOnEmptyFocus = options.popupOpenOnEmptyFocus ?? false;
    this.baseQuery = options.baseQuery;
    this.columns = options.columns;
    this.autoBuildQuery = options.autoBuildQuery ?? true;
    this.filterRunDelay = options.filterRunDelay;

    this.cssClass = 'e-multi-column';

    this.fieldMeta = options.fieldMeta;
    if (this.fieldMeta) {
      this.initFromFieldMetaData(options);
    }

    if (this.columns?.filter(c => c.visible !== false).length > 1) {
      if (!this.headerTemplate) {
        this.headerTemplate = this.getTemplate(true);
      }
      if (!this.itemTemplate) {
        this.itemTemplate = this.getTemplate(false);
      }
    }

    if (options?.filterType === undefined) {
      this.filterType = this.defaultSearchOperator;
    }
    if (options?.query === undefined) {
      this.query = new Query();
    }
  }

  protected initFromFieldMetaData(options: any) {
    if (this.fieldMeta) {
      var meta: FieldMetadata = this.fieldMeta;

      // Data
      if (!this.data && this.fieldMeta.foreignKeyData) {
        this.data = Array.from(this.fieldMeta.foreignKeyData);
      }
      // Appearance
      if (options?.showClearButton === undefined) {
        this.showClearButton = meta.allowClear !== false;
      }

      if (options?.popupOpenOnEmptyFocus === undefined && meta.foreignKeySearchOnEmptyFocus !== undefined) {
        this.popupOpenOnEmptyFocus = meta.foreignKeySearchOnEmptyFocus;
      }

      // Columns
      if (options?.columns === undefined) {
        var columns: Column[] = [];
        if (meta.foreignKeyColumns?.length > 0) {
          meta.foreignKeyColumns.forEach((columnName, index) => {
            var column: Column = <any>{};
            column.field = columnName;
            column.headerText = meta.foreignKeyTitles?.length > index ? meta.foreignKeyTitles[index] : undefined;
            column.width = meta.foreignKeyColumnsWidth?.length > index ? meta.foreignKeyColumnsWidth[index] : undefined;
            columns.push(column);
          });
        }
        else if (meta.foreignKeyValue) {
          var column: Column = <any>{};
          column.field = meta.foreignKeyValue;
          columns.push(column);
        }
      }
      this.columns = columns;
    }

    if (options.selectColumnNames === undefined) {
      let selectColumnNames: string[] = [meta.foreignKeyValue ?? 'name', meta.foreignKeyField ?? 'id'];
      if (meta.foreignKeyColumns?.length > 0) {
        selectColumnNames = selectColumnNames.concat(meta.foreignKeyColumns);
      }
      if (meta.foreignKeySearchFields?.length > 0) {
        selectColumnNames = selectColumnNames.concat(meta.foreignKeySearchFields);
      }
      if (meta.foreignKeySortFields?.length > 0) {
        selectColumnNames = selectColumnNames.concat(meta.foreignKeySortFields);
      }
      let selectColumnNamesUnique: string[] = [...new Set(selectColumnNames)].filter((f) => f?.length > 0);
      this.selectColumnNames = selectColumnNamesUnique;
    }

    // Appearance
    if (options?.popupHeight === undefined && options.popupWidth === undefined) {
      var isLarge: boolean = this.columns?.length > 0;
      this.popupHeight = meta.popupHeight != null ? meta.popupHeight : isLarge ? '300px' : '200px';
      this.popupWidth = meta.popupWidth != null ? meta.popupWidth : isLarge ? '400px' : '200px';
    }

    // Data
    //this.enableVirtualization =  true;
    //this.query = this.getQueryFromFieldMeta(null);

    if (options?.sortOrder === undefined) {
      this.sortOrder = meta.foreignKeySortFields?.length > 0 ? 'None' : 'Ascending';  // Query already sorts  
    }
    if (options?.allowFiltering === undefined) {
      this.allowFiltering = meta.foreignKeyAllowFiltering ?? true;
    }

    this.dataSave();
  }

  override wireEvent() {
    super.wireEvent();

    EventHandler.remove(this.inputWrapper.container, 'keypress', (<any>this).onSearch);

    this.addEventListener('open', this.onCerOpenPopup);
    this.addEventListener('actionBegin', this.onFilterActionBegin);
    this.addEventListener('actionFailure', this.onFilterActionFailure);
    this.addEventListener('actionComplete', this.onFilterActionComplete);
    this.addEventListener("filtering", this.onFiltering);
    this.addEventListener("change", this.onValueChange);

    document.addEventListener("paste", this.pasteHandlerInputDoc.bind(this));

    //EventHandler.add(document, "paste", this.pasteHandlerInputDoc, this);
    EventHandler.add(this.inputWrapper.container, 'keypress', this.onKeypress_Input);
    EventHandler.add(this.inputWrapper.container, 'focus', this.onFocusInput);
    EventHandler.add(this.inputWrapper.container, 'paste', this.pasteHandlerInput, this);
    EventHandler.add(this.inputWrapper.container, 'keyDown', this.keyDownHandlerInput, this);

  }

  override unWireEvent() {
    EventHandler.remove(this.inputWrapper.container, 'keydown', this.keyDownHandlerInput);
    EventHandler.remove(this.inputWrapper.container, 'paste', this.pasteHandlerInput);
    EventHandler.remove(this.inputWrapper.container, 'focus', this.onFocusInput);
    EventHandler.remove(this.inputWrapper.container, 'keypress', this.onKeypress_Input);

    if (this.inputElement) {
      /* why?
      this.inputElement.parentElement.parentElement.addEventListener("paste", this.pasteHandlerInputDoc);
      this.inputElement.parentElement.addEventListener("paste", this.pasteHandlerInputDoc);
      this.inputElement.addEventListener("paste", this.pasteHandlerInputDoc);
      */
      //EventHandler.remove(this.inputElement, 'paste', this.pasteHandlerInput);
    }

    document.removeEventListener("paste", this.pasteHandlerInputDoc);

    this.removeEventListener("change", this.onValueChange);
    this.removeEventListener("filtering", this.onFiltering);
    this.removeEventListener('actionComplete', this.onFilterActionComplete);
    this.removeEventListener('actionFailure', this.onFilterActionFailure);
    this.removeEventListener('actionBegin', this.onFilterActionBegin);
    this.removeEventListener('open', this.onCerOpenPopup);

    super.unWireEvent();
  }

  protected onFiltering(e: FilteringEventArgs) {
    //if (this.isCustomFilter) {
    //  this.searchListsDeferred(false);
    //}
  }

  protected onFilterActionBegin(args: any) {
  }

  protected onFilterActionFailure(args: any) {
    //console.log('filter ActionFailure', args.query);
    this.searchListsDeferred(false);
  }

  override onActionComplete(list: any, e: any, isUpdated: any) {
    super.onActionComplete(list, e, isUpdated);
    this.searchListsDeferred(false);
  }
  protected onFilterActionComplete(args: any) {
    if (args?.query?.filterActiveText) {
      this.filterCompletedText = args.query.filterActiveText;
    }
    this.searchListsDeferred(false);
  }

  protected onValueChange(e: ChangeEventArgs) {
  }

  override render() {
    // this.cloneDataSource();
    super.render();
  }

  override postRender(listElement: any, list: any, bindEvent: any) {
    super.postRender(listElement, list, bindEvent);

    //if (this.enqueueFiltering) {
    //  this.enqueueFiltering = false;
    this.isSelected = true;
    var e: KeyboardEvent = new KeyboardEvent("force", null);
    //this.onChangeEvent(e);
    //}
  }

  /*
  override showSpinner(): void {
    if (!this.spinnerElement) {
      var filterInputObj = (<any> this).filterInputObj;
      this.spinnerElement = Browser.isDevice && !isNullOrUndefined(filterInputObj) && filterInputObj.buttons[1] ||
        !isNullOrUndefined(filterInputObj) && filterInputObj.buttons[0] || this.inputWrapper.buttons[0];
      addClass([this.spinnerElement], dropDownListClasses.disableIcon);
      createSpinner({
        target: this.spinnerElement,
        width: Browser.isDevice ? '16px' : '14px'
      }, this.createElement);
      showSpinner(this.spinnerElement);
    }
  }
    */

  protected cloneDataSource() {
    if (this.data) {
      //var data = Array.from(this.data);
      // this.dataSource = new DataManager(data);
      //console.log('clone dataSource count', data.length);
    }
  }

  protected override getQuery(query: Query): Query {
    this.cloneDataSource();

    if (this.autoBuildQuery) {
      var searchText = this.calcFilterText(null);
      query = this.onCerBuildQuery(query, searchText);
    }
    else {
      query = super.getQuery(query);
    }

    return query;
  }

  private isPopupOpening: boolean = false;
  public override showPopup(e?: MouseEvent | KeyboardEventArgs | TouchEvent) {
    this.isPopupOpening = true;
    super.showPopup();
    this.isPopupOpening = false;
  }

  public autoOpenPopup() {
    if (!this.isPopupOpen && !this.value && this.popupOpenOnEmptyFocus) {
      this.showPopup();
    }
  }

  public hasContainerFocus(): boolean {
    var hasFocus: boolean = false;

    if (document.activeElement && this.inputWrapper && this.inputWrapper.container) {
      hasFocus = (document.activeElement == this.inputWrapper.container);
    }
    return hasFocus;
  }

  private onFocusInput(e: any) {
    var ddl: CerDropDownList = CerDropDownList.getDdlFromInputContainer(e.currentTarget);
    if (ddl) {
      ddl.autoOpenPopup();
    }
  }

  // Saved => Reload data onCerActionBegin
  private data: any[];
  protected dataSave() {
    if (!this.data) {

      if (this.dataSource instanceof DataManager) {
        var dm = this.dataSource as DataManager;
        var dataOrig: any[] = dm.dataSource?.json;
        if (dataOrig) {
          if (dataOrig?.length > 0) {
            this.data = dataOrig;
            //console.log('dataSourceOrig count', this.data?.length);
          }
        }
      }
    }
  }

  private filterTextDeferred: string = null;
  public calcFilterText(e: FilteringEventArgs = null, addition: string = null, append = true): string {
    var filterText: string = e?.text ?? '';
    var filterInput: HTMLInputElement = this.filterInput;
    if (filterInput?.value) {
      filterText = filterInput.value;
    }
    else if (this.filterTextDeferred) {
      filterText = this.filterTextDeferred;
      this.filterTextDeferred = null;
    }
    if (addition) {
      filterText = (append && filterText ? filterText : '') + addition;
    }

    if (filterText) {
      if (filterInput) {
        filterInput.value = filterText;
      }
      else {
        this.filterTextDeferred = filterText;
      }
    }
    this.typedString = filterText;
    if (e) {
      e.text = filterText;
    }
    //console.log('filter = ', filterText);
    return filterText;
  }

  override searchLists(args: any): void {
    if (this.checkSearchList(args)) {
      super.searchLists(args);
    }
    else {
      //console.log('searchLists deferred', this.filterRunState);
    }
  }

  private filterRunState: 'ready' | 'waiting' | 'readyWaiting' | 'running' = 'ready';
  private filterRunDeferredArgs: any;

  public searchListsDeferred(runDeferredNow: boolean = false) {
    if (this.filterRunState == 'running') {
      this.filterRunState = (this.filterRunDeferredArgs != null) ? 'readyWaiting' : 'ready';
    }
    if (this. filterRunState == 'readyWaiting' && this.filterRunDeferredArgs != null) {
      // We are ready to run the deferred filter (after the current event completes)
      if (runDeferredNow) {
        this.searchListsDeferredExecute();
      }
      else {
        var _this = this;
        setTimeout(() => {
          _this.searchListsDeferredExecute();
        }, 0);
      }
    }
  }

  private searchListsDeferredExecute() {
    if (this.filterInput?.parentElement != null) {
      var argsRun = this.filterRunDeferredArgs;
      if (argsRun != null) {
        {
          argsRun.preventDefaultAction = false;
          this.filterRunDeferredArgs = null;
          this.searchLists(argsRun);
        }
      }
    }
  }

  private checkSearchList(args: any): boolean {
    if (this.filterInput?.parentElement == null) {
      // The input has been removed, don't run the filter
      console.log('input parent removed');
      this.filterStateSetReady();
      return false;
    }
    var canRunNow: boolean = this.filterRunState == 'ready' || this.filterRunState == 'readyWaiting';
    if (args != null) {
      if (this.filterRunState == 'ready') {
        // Run delayed (because we would want the user to type more characters before executing the filter)
        if (this.mustRunDelayed(args)) {
          canRunNow = false;
          this.filterRunState = 'waiting';
          var _this = this;
          setTimeout(() => {
            _this.filterRunState = 'readyWaiting'; // Delay is over, execute any deferred filter
            _this.searchListsDeferred(true);
          }, this.filterRunDelay);
        }
      }
      if (!canRunNow) {
        this.filterRunDeferredArgs = args;
        args.preventDefaultAction = true;
      }
      else {
        this.filterRunState = 'running';
        this.typedString = this.calcFilterText(args);
        if (args == null) {
          args = {};
        }
        args.text = this.typedString;
        args.query = this.getQuery(args.query);
      }
    }
    return canRunNow;
  }

  private mustRunDelayed(args: any): boolean {
    // If typed text is too short or clipboard event, don't run delayed
    var isClipboardEvent: boolean = (args?.baseEventArgs != null && args.baseEventArgs instanceof ClipboardEvent);
    var canRunDelayed = !isClipboardEvent && this.filterRunDelay > 0;
    if (canRunDelayed) {
      var minFilterLength: number = this.filterRunDelayFilterLengthMin;
      if (minFilterLength) {
        var currFilterText = this.calcFilterText();
        canRunDelayed = currFilterText && currFilterText.length >= minFilterLength;
        //console.log('filterCanRunDelayed ', canRunDelayed, currFilterText, this.filterRunState);
      }
    }
    return canRunDelayed;
  }

  protected onKeypress_Input(e: any) {
    if (e.key?.length > 0) {
      let ddl: CerDropDownList = CerDropDownList.getDdlFromInputContainer(e.currentTarget);
      if (ddl) {
        if (e.charCode !== 32 && e.charCode !== 13) {
          ddl.openPopupWithKeyboardEvent(e);
        }
        else if (e.charCode == 32) {
          ddl.showPopup(e);
        }
      }
    }
  }

  public pasteHandlerInputDoc(e: any) {
    if (e.cerHandled !== true) {
      let ddl: CerDropDownList = CerDropDownList.getDdlFromInputContainer(document.activeElement);
      if (ddl) {
        e.cerHandled = true;
        ddl.pasteHandlerInput(e);
      }
    }
  }

  public keyDownHandlerInput(e: any) {
    console.log('keyDownHandlerInput ' + e.value);
  }

  public pasteHandlerInput(e: any) {
    if (e.clipboardData) {
      var text: string = e.clipboardData.getData('Text');
      this.openPopupWithKeyboardValue(text, e, false);
    }
  }

  public openPopupWithKeyboardEvent(e: any) {
    var text: string = e?.key;
    this.openPopupWithKeyboardValue(text, e, true);
  }

  public openPopupWithKeyboardValue(char: string, e: any, append: boolean) {
    if (this.allowFiltering && char) {
      var filterText = this.calcFilterText(e, char, true);
      if (!this.isPopupOpen && !this.isPopupOpening) {
        this.showPopup();
      }
      else {
        console.log('openPopupWithKeyboardEvent filterText', filterText);
        this.filterText(filterText, e);
      }
      e.preventDefault();
      e.cancelBubble = true;
    }
  }

  public filterStateSetReady() {
    this.filterRunState = 'ready';
  }

  protected onCerOpenPopup(e: any) {
    var filterText = this.calcFilterText(e);
    this.filterStateSetReady();
    if (this.allowFiltering && filterText) {
      this.filterText(filterText, e);
    }
  }

  private filterText(text: string, e: any) {
    this.searchLists(e);
    if (this.enableVirtualization) {
      this.getFilteringSkeletonCount();
    }
  }

  /*
  override setListData(dataSource: any, fields: any, query: any, event: any) {
    super.setListData(dataSource, fields, query, event);
    this.lastFilteredValue = this.filterInput.value;
  }*/

  protected override selectCurrentValueOnTab(e: any) {
    this.selectCurrentItem(e);
    /*
      if (this.getModuleName() === 'autocomplete') {
          this.selectCurrentItem(e);
      }
      else {
          if (this.isPopupOpen) {
              this.hidePopup(e);
              this.focusDropDown(e);
          }
      }
    }
    */
  }

  // When selecting from the down, does the list represent the current filter?
  protected isSearchListCurrent(): boolean {
    var isCurrent: boolean = true;
    if (this.allowFiltering) {
      var filterText: string = this.calcFilterText();
      isCurrent = (!this.filterCompletedText || this.filterCompletedText == filterText);
      if (!isCurrent) {
        console.log('Query not current: "' + this.filterCompletedText + '" != "' + filterText + '"');
      }
    }
    return isCurrent;
  }

  override selectCurrentItem(e: any) {
    if (this.isPopupOpen) {
      if (!this.isSearchListCurrent()) {
        this.searchLists(e);
      }
      else {
        let li = this.list.querySelector('.' + dropDownListClasses.focus);
        if (li) {
          this.setSelection(li, e);
          this.isTyped = false;
        }
        if (this.isSelected) {
          this.isSelectCustom = false;
          this.onChangeEvent(e);
        }
        this.hidePopup();
        // @ts-ignore
        this.focusDropDown(e);
        /*
                else {
                  if (this.filterInput.value.length > 0) {
                    this.buildQueryForced = true;
                    this.enqueueFiltering = true;
                    this.searchLists(e);
                  }
                  else {
                    //if (!this.isSelected) {
                    //  this.onChangeEvent(e);
                    //}
                    this.hidePopup();
                    this.focusDropDown(e);
                  }
                }*/
      }
    }
    else {
      this.showPopup();
    }
  }

  protected selectFirstItem(e: any) {
    if (this.isPopupOpen) {
      let li = <Element>this.list?.firstChild?.firstChild;
      if (li) {
        this.setSelection(li, e);
        this.isTyped = false;
      }
      if (this.isSelected) {
        this.isSelectCustom = false;
        this.onChangeEvent(e);
      }
      else {
        if (!this.isSelected) {
          this.onChangeEvent(e);
        }
        this.hidePopup();
        // @ts-ignore
        this.focusDropDown(e);
      }
    }
    else {
      this.showPopup();
    }
  }

  // Data binding
  protected onCerBuildQuery(query: Query, searchText: string): Query {
    //console.log('onCerbuildQuery=', searchText, '- ', (this.dataSource as any)?.dataSource?.json?.length);

    let queryNew: Query = this.baseQuery ? this.baseQuery.clone() : new Query();

    if (this.selectColumnNames?.length > 0) {
      queryNew = queryNew.select(this.selectColumnNames);
    }

    var queryPredicate: Predicate = this.fieldMeta ? this.searchPredicateFromMeta(this.fieldMeta, searchText) : this.searchPredicate(searchText);

    var queryPredicateIdNotNull = new Predicate(this.fields.value, 'notequal', null, true, false);
    queryPredicate = (!queryPredicate) ? queryPredicateIdNotNull : new Predicate(queryPredicate, 'and', queryPredicateIdNotNull);

    if (queryPredicate) {
      queryNew = queryNew.where(queryPredicate);
    }

    if (this.fieldMeta?.foreignKeySortFields?.length > 0) {
      queryNew = queryNew.sortBy(this.fieldMeta?.foreignKeySortFields);
    };

    return queryNew;
  }

  protected searchPredicate(searchText: string = null): Predicate {
    var queryPredicate: Predicate;
    if (searchText?.length > 0) {
      var searchColumns = this.columns?.filter(c => c.allowFiltering !== false);
      if (searchColumns?.length > 0) {
        searchColumns.forEach(c => {
          let searchField: string = c.field;
          let searchOperator: string = (c as any).searchOperator ?? this.defaultSearchOperator;
          let predicateSearch: Predicate = new Predicate(searchField, searchOperator, searchText, true, true, false);
          queryPredicate = queryPredicate ? new Predicate(queryPredicate, 'or', predicateSearch) : predicateSearch;
        });
      }
      else if (this.fields?.text?.length > 0) {
        let searchFields: string[] = [this.fields.text];
        let searchOperators: string[] = [];
        for (let i = 0; i < searchFields.length; i++) {
          let searchField: string = searchFields[i];
          let searchOperator: string = (searchOperators?.length > i) ? searchOperators[i] : this.filterType;
          let predicateSearch: Predicate = new Predicate(searchField, searchOperator, searchText, true, true, false);
          queryPredicate = queryPredicate ? new Predicate(queryPredicate, 'or', predicateSearch) : predicateSearch;
        }
      }
    }
    return queryPredicate;
  }

  protected searchPredicateFromMeta(meta: FieldMetadata, searchText: string = null): Predicate {
    var queryPredicate: Predicate;

    if (meta) {
      if (searchText?.length > 0 && meta) {
        let searchFields: string[] = meta.foreignKeySearchFields;
        let searchOperators: string[] = meta.foreignKeySearchOperators;
        if (!(searchFields?.length > 0)) {
          if (meta.foreignKeyColumns?.length > 0) {
            searchFields = meta.foreignKeyColumns;
          }
          else if (meta.foreignKeyValue?.length > 0) {
            searchFields = [meta.foreignKeyValue];
          }
          searchOperators = [];
        }
        if (searchFields?.length > 0) {
          for (let i = 0; i < searchFields.length; i++) {
            let searchField: string = searchFields[i];
            let searchOperator: string = (searchOperators?.length > i) ? searchOperators[i] : this.filterType;
            let predicateSearch: Predicate = new Predicate(searchField, searchOperator, searchText, true, true, false);
            queryPredicate = queryPredicate ? new Predicate(queryPredicate, 'or', predicateSearch) : predicateSearch;
          }
        }
      }
      if (meta.foreignKeyFilterFields?.length > 0) {
        let filterFields: string[] = meta.foreignKeyFilterFields;
        let filterOperators: string[] = meta.foreignKeyFilterOperators;
        let filterValues: any[] = meta.foreignKeyFilterValues;
        for (let i = 0; i < filterFields.length; i++) {
          let filterField: string = filterFields[i];
          let filterOperator: string = (filterOperators?.length >= i) ? filterOperators[i] : this.defaultSearchOperator;
          let filterValue: any = (filterOperators?.length >= i) ? filterValues[i] : undefined;
          if (filterValue !== undefined) {
            let predicateFilter: Predicate = new Predicate(filterField, filterOperator, filterValue, true, true, false);
            queryPredicate = queryPredicate ? new Predicate(queryPredicate, 'and', predicateFilter) : predicateFilter;
          }
        }
      }
    }
    return queryPredicate;
  }

  protected getTemplate(isHeader: boolean): string {
    let template: string;
    let hasHeader: boolean = false;
    let len: number = this.columns?.length ?? 0;
    if (len > 0) {
      let spanClass: string = isHeader ? 'ddl-header' : 'ddl-item';
      let cellClass: string = isHeader ? 'ddl-header-col' : 'ddl-col';
      template = ''; // '<span class="' + spanClass + '">';

      this.columns.forEach((column, index) => {
        if (column.visible !== false) {
          if (isHeader && column.headerText?.length > 0) {
            hasHeader = true;
          }
          var colValue: string = isHeader ? (column.headerText ?? column.field) : '${' + column.field + '}';

          var style: string = '';
          if (column.width != null) {
            var width: string = (column.width as string);
            if (width.length > 0) {
              style = ' style="width: ' + column.width + '"';
            }
          }
          template += '<span class="' + cellClass + '"' + style + '>' + colValue + '</span>';
        }
      });

      //template += '</span>';
    }

    if (isHeader && !hasHeader) {
      template = undefined;
    }

    return template;
  }

  /*
  private onSearchFreeText(e: any) {
    if (e.charCode !== 32 && e.charCode !== 13) {
      var ddl: snDropDownListSearch = getDdlFromInputContainer(e);
      if (ddl) {
        ddl.openPopupWithKeyboardValue(e);
      }
      e.preventDefault();
      e.cancelBubble = true;
    }
  }

  override incrementalSearch(e: KeyboardEventArgs) {
    if (e.code !== 'KeySpace' && e.code != 'KeyEnter' && e.code != 'KeyDown' && !this.isPopupOpen) {
      this.openPopupWithKeyboardValue(e);
    }
    else {
      super.incrementalSearch(e);
    }
  }
  */
}

