<ejs-toolbar #toolbar (clicked)="toolbarClick($event)" (created)="toolbarCreated($event)">
  <e-items>
    <e-item id="uploadShowHide" text="Vedhæft fil"></e-item>
    <e-item>
      <ng-template #template>
        <span [class]="attachmentUrlOriginalClass">
          <button class="e-tbar-btn e-tbtn-txt e-control e-btn e-lib" type="button" id="downloadOriginal"
            data-ripple="true" tabindex="1" data-tabindex="-1" aria-label="Åbn" aria-disabled="false"
            style="width: auto;">
            <span class="e-tbar-btn-text" _ngcontent-ng-cli-universal-c1650358587="">
              <a class="e-item" target="_blank" [href]="attachmentUrlOriginal">Åbn</a>
            </span></button>
        </span>
      </ng-template>
    </e-item>
    <e-item>
      <ng-template #template>
        <span [class]="attachmentUrlDisplayClass">
          <button class="e-tbar-btn e-tbtn-txt e-control e-btn e-lib" type="button" id="downloadOriginal"
            data-ripple="true" tabindex="1" data-tabindex="-1" aria-label="Åbn" aria-disabled="false"
            style="width: auto;">
            <span class="e-tbar-btn-text" _ngcontent-ng-cli-universal-c1650358587="">
              <a class="e-item" target="_blank" [href]="attachmentUrlDisplay">Åbn vist</a>
            </span>
          </button>
        </span>
      </ng-template>
    </e-item>
    <e-item id="zoomIn" text="+" tooltipText="Zoom ind (ALT +)"></e-item>
    <e-item id="zoomOut" text="-" tooltipText="Zoom ud (ALT -)"></e-item>
    <e-item id="tabCheckboxShow" text="Split" [visible]="false"></e-item>
    <e-item id="tabCheckboxApply" text="Udfør split" [visible]="false"></e-item>
    <e-item id="close" text="X" tooltipText="Luk vedhæftninger (Escape)" align="Right"></e-item>
  </e-items>
</ejs-toolbar>
<div #defaultupload [hidden]="!uploadIsActive" class="uploadDropArea flex-static" id="uploadDropArea">
  <ejs-uploader *ngIf="uploadIsActive" [asyncSettings]="uploadPath" [dropArea]="uploadDropAreaElement"
    sequentialUpload="true" (uploading)="onUploading($event)" (success)="onUploadSuccess($event)"
    style="height: 100%; width: 100%"></ejs-uploader>
</div>
<div class="tab-container">
  <mat-tab-group mat-align-tabs="start" class="mat-tab-group-flex" [(selectedIndex)]="tabSelectedIndex"
    (selectedTabChange)="onSelectedTabChanged($event)" animationDuration="0ms">
    <mat-tab #tabGroup on *ngFor="let attachment of attachments" [ngSwitch]="true">
      <ng-template mat-tab-label>
        <div *ngIf="tabCheckBoxMode">
          <mat-checkbox name="TabCheckeBox{{ attachment.attachmentIndex }}"
            (change)="tabCheckboxSet(attachment, $event)"
            [checked]="tabChecked[attachment.attachmentIndex]"></mat-checkbox>
        </div>
        <div *ngSwitchCase="this.contentIsBody(attachment)">
          <mat-icon class="tab-icon">email</mat-icon>E-mail
        </div>
        <div *ngSwitchCase="this.contentIsPdf(attachment)" [title]="attachment.fileName">
          <mat-icon *ngIf="!attachment.originalFileExtension" class="tab-icon">picture_as_pdf</mat-icon>
          <mat-icon *ngIf="attachment.originalFileExtension" class="tab-icon">description</mat-icon>
          {{ attachment.title }}
        </div>
        <div *ngSwitchCase="this.contentIsImage(attachment)" [title]="attachment.fileName">
          <mat-icon class="tab-icon">image</mat-icon>{{ attachment.title }}
        </div>
        <div *ngSwitchCase="this.contentIsLink(attachment) || this.contentIsText(attachment)" [title]="attachment.fileName">
          <mat-icon class="tab-icon">attachment</mat-icon>{{ attachment.title
          }}{{ attachment.fileExtension }}
        </div>
      </ng-template>
      <ng-template matTabContent>
        <div *ngSwitchCase="this.contentIsBody(attachment)" class="tab-content" [style]="zoomStyle">
          <div style="height: 100%; display: flex; flex-direction: column">
            <div style="flex-grow: 0">
              <section *ngIf="attachment.properties?.length > 0" class="cer-secondary"
                style="display: table; width: 100%; padding: 10px;">
                <div *ngFor="let property of attachment.properties" style="display: table-row">
                  <div style="display: table-cell; padding: 1px; padding-left: 4px">
                    {{ property.key }}
                  </div>
                  <div style="display: table-cell; padding: 1px; padding-left: 4px">
                    {{ property.value }}
                  </div>
                </div>
              </section>
            </div>
            <div class="flex-grow">
              <iframe [src]="attachment.url | safe"></iframe>
            </div>
          </div>
        </div>
        <div *ngSwitchCase="this.contentIsText(attachment)" class="tab-content" [style]="zoomStyle">
          <div style="height: 100%; display: flex; flex-direction: column">
           <iframe [src]="this.plainUrl(attachment) | safe"></iframe>
         </div>
        </div>
        <div *ngSwitchCase="this.contentIsPdf(attachment)" class="tab-content">
          <pdf-viewer #pdfViewer id="pdfViewer" [src]="attachment.url" zoom-scale="page-width"
            [original-size]="false"></pdf-viewer>
        </div>
        <div *ngSwitchCase="this.contentIsImage(attachment)" class="tab-content">
          <div class="flex-grow" [style]="zoomStyle">
            <img [src]="attachment.url" width="100%" />
          </div>
        </div>
        <div *ngSwitchCase="this.contentIsLink(attachment)" class="tab-content">
          <div class="fileDownload mat-toolbar.mat-primary">
            <a [href]="attachment.url">
              <div>Hent fil</div>
              <div>
                {{ attachment.fileName }}{{ attachment.fileExtension }}
              </div>
            </a>
          </div>
        </div>
      </ng-template>
    </mat-tab>
    <mat-tab #tabGroup *ngIf="enableChat && refRowId">
      <ng-template mat-tab-label>
        <div>
          <mat-icon class="tab-icon">message</mat-icon>Chat{{
          chats && chats.length > 0 ? " (" + chats.length + ")" : ""
          }}
        </div>
      </ng-template>
      <ng-template matTabContent>
        <div class="tab-content">
          <cer-app-chat [chats]="chats" [refTableId]="refTableId" [refRowId]="refRowId" [privateNoteActive]="privateNoteActive">
          </cer-app-chat>
        </div>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>