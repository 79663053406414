// Angular
import { Injectable, Query } from '@angular/core';

// WebApi
import {
  VoucherMessageViewDetailsVm as DetailsVm,
  VoucherMessageViewDto as MessageDto,
  VoucherViewDto as VoucherDto,
  VoucherMessageStatusEnum,
  VoucherApprovalStatusEnum,
  VoucherApprovalStatusAllEnum,
  VoucherMessageChannelTypeEnum,
  ChatDto,
} from "../../api";

// App
import { CerGridComponent } from 'src/cer/cer-grid/cer-grid.component';
import { CerAppChatService } from 'src/cer-app/cer-app-chat/cer-app-chat.service';
import { UiActionTypeEnum, UiCommand, UiCommandEvent, UiCommandSourceEnum, UiKeyboardShortcut, uiCmdSeparator } from 'src/cer/cer-grid/cer-grid-command.service';
import { CerGridSelectionService } from 'src/cer/cer-grid/cer-grid-selection.service';
import { CerFormComponent } from 'src/cer/cer-form/cer-form.component';
import { FieldGroupMetadata, FieldMetadata, ViewMetadata } from 'src/cer/cer-data/cer-data.service';

// Voucher
import { VoucherDataService, VoucherUpdateResult } from '../voucher-data.service';
import { uiCmdPaneRightClose, uiCmdPaneRightToggle } from 'src/cer/cer-form/cer-form-panes.service';
import { CerDialogService } from 'src/cer/cer-dialog/cer-dialog.service';
import { ContextMenuOpenEventArgs, QueryCellInfoEventArgs } from '@syncfusion/ej2-angular-grids';
import { VoucherHelper } from '../voucher.service';
import { Subscription } from 'rxjs';
import { CerAppFileViewerTabComponent } from 'src/cer-app/cer-app-file-viewer-tab/cer-app-file-viewer-tab.component';
import { VoucherFormatterValidationStatus } from './voucher-formatter-validation-status';

@Injectable(
)

export class VoucherViewService {
  // State
  private multiSelectApprovalStatusTo: VoucherApprovalStatusEnum;

  // Commands 
  private uiCmdShowVoucherDetails: UiCommand = { id: 'showVoucherDetails', text: 'Vis bilag', tooltipText: ' godkendelse og kontering (ALT+X)', iconCss: 'e-menu-icon e-icons-org e-detail', target: ".e-content" };
  private uiCmdSetStatusCreated: UiCommand = { id: 'uiCmdSetStatusCreated', text: "Sæt status 'Oprettet'", iconCss: "e-menu-icon e-icons-org e-document-01", target: ".e-content", data: { approvalStatus: VoucherApprovalStatusEnum.Created } };
  private uiCmdSetStatusPending: UiCommand = { id: 'uiCmdSetStatusPending', text: "Sæt status 'Til godkendelse'", iconCss: 'e-menu-icon e-icons-org e-document-01', target: ".e-content", data: { approvalStatus: VoucherApprovalStatusEnum.Pending } };
  private uiCmdSetStatusOnHold: UiCommand = { id: 'uiCmdSetStatusOnHold', text: "Sæt status 'Afventer'", iconCss: "e-menu-icon e-icons-org e-document-01", target: ".e-content", data: { approvalStatus: VoucherApprovalStatusEnum.OnHold } };
  private uiCmdSetStatusApproved: UiCommand = { id: 'uiCmdSetStatusApproved', text: "Sæt status 'Godkendt'", iconCss: "e-menu-icon e-icons-org e-document-01", target: ".e-content", data: { approvalStatus: VoucherApprovalStatusEnum.Approved } };
  private uiCmdSetStatusAccounted: UiCommand = { id: 'setStatusAccounted', text: "Sæt status 'Afsluttet'", iconCss: "e-menu-icon e-icons-org e-close-01", target: ".e-content", data: { approvalStatus: VoucherApprovalStatusEnum.Accounted } };
  private uiCmdSetStatusPosted: UiCommand = { id: 'setStatusPosted', text: "Sæt status 'Bogført'", iconCss: "e-menu-icon e-icons-org e-document-01", target: ".e-content", data: { approvalStatus: VoucherApprovalStatusEnum.Posted } };
  private uiCmdSetStatusRejected: UiCommand = { id: 'setStatusRejected', text: "Sæt status 'Annulleret'", iconCss: "e-menu-icon e-icons-org e-close-01", target: ".e-content", data: { messageStatus: VoucherMessageStatusEnum.Rejected } };
  private uiCmdSetStatusNextMulti: UiCommand = { id: 'setStatusNextMulti', text: 'Afslut markerede', tooltipText: 'Set status på markerede [Alt+M]' };

  // Global Keyboard shortcuts
  private uiCmdAttachmentZoomIn: UiCommand = { id: 'attachmentZoomIn', text: 'Zoom ind', tooltipText: 'Zoom ind [Alt]+[Numpad +]' };
  private uiCmdAttachmentZoomOut: UiCommand = { id: 'attachmentZoomOut', text: 'Zoom ud', tooltipText: 'Zoom ud [Alt]+[Numpad -]' };
  private uiCmdAttachmentPageUp: UiCommand = { id: 'attachmentPageUp', text: 'Side op', tooltipText: 'Side op Shift]+[Alt]+[PageUp]' };
  private uiCmdAttachmentPageDown: UiCommand = { id: 'attachmentPageDown', text: 'Side ned', tooltipText: 'Side ned Shift]+[Alt]+[PageDown]' };
  private uiCmdPaneRightWidthAdd: UiCommand = { id: 'paneRightWidthAdd', text: 'Forøg bredde højre', tooltipText: 'Forøg bredde [Shift]+[Alt]+[Numpad +]' };
  private uiCmdPaneRightWidthDecr: UiCommand = { id: 'paneRightWidthDecr', text: 'Formindsk bredde højre', tooltipText: 'Formindsk bredde [Shift]+[Alt]+[Numpad -]' };

  // Ui commands
  private uiCmdTabToggle = Object.assign(new Object(), uiCmdPaneRightToggle, { text: 'Vis vedhæftninger', tooltipText: 'Vis vehæftninger i højre side [Ctrl]+[Pil højre]' });
  //private uiCmdPostingToggle = Object.assign(new Object(), uiCmdBottomToggle, { text: 'Vis kontering', tooltipText: 'Vis kontering nederst [Ctrl]+[Pil ned]' });
  private uiCmdTabClose = Object.assign(new Object(), uiCmdPaneRightClose, { text: '', tooltipText: 'Luk vedhæftninger [Ctrl]+[Pil op]' });
  //private uiCmdPostingClose = Object.assign(new Object(), uiCmdBottomClose, { text: 'Kontering', tooltipText: 'Luk kontering [Ctrl]+[Pil venste]' });

  // View
  private viewMetadata: ViewMetadata = {
    name: 'bilag', dataApiName: 'VoucherView', dataApiParams: [],
    text: 'Bilag', textSingular: 'Bilag', titleFields: ['description', 'statusName'],
    allowEdit: false, allowDelete: false, allowCreate: false,
    primaryKey: 'id', baseFields: ['id']
  };

  private voucherFieldGroupMetadata: FieldGroupMetadata[] = [
    { idx: 1, tabIdx: 0, name: 'identification', text: 'Bilag', default: true, fields: ['voucherNum','txt','transDate'] },
    { idx: 2, tabIdx: 0, name: 'sales', text: 'Salgsbilag', fields: ['salesFromDate', 'salesToDate', 'salesIsBOM'] },
  ]

  private voucherFieldMetadata: FieldMetadata[] = [
    { name: 'chat', text: 'Chat', tooltipText: 'Se chatbeskeder', allowEdit: false, format: 'Chat', visibleAdd: false, visibleEdit: false },
    { name: 'id', text: "Bilag id", format: 'Integer', visible: false, width: '90px' },
    { name: 'msgId', text: "Besked id", format: 'Integer', visible: false, width: '90px' },
    { name: 'voucherNum', text: "Bilagsnr.", visible: false, width: '100px' },
    { name: 'postedStatusName', text: "Bogføring", width: '100px', visible: false },
    { name: 'status', text: "Status id", visible: false, width: '80px' },
    { name: 'validationStatus', text: "Genkendelsestatus", tooltipText: "Status for automatisk genkendelse af dokument", width: '20px', foreignKeyTableName: 'VoucherValidationStatus', formatter: new VoucherFormatterValidationStatus(), visible: false },
    { name: 'statusName', text: "Status", visible: false, width: '120px' },
    { name: 'balanceAccountNum', text: "Konto", visible: false, width: '100px' },
    { name: 'balanceAccountDescription', text: "Konto navn", visible: false, width: '120px' },
    { name: 'balanceAccountTaxCode', text: "Konto momskode", visible: false, width: '70px' },
    { name: 'authReference', text: "Nota Indløser", visible: false, width: '60px' },
    { name: 'transDate', text: "Dato", format: 'DateShort', width: '100px' },
    { name: 'documentDate', text: "Document dato", format: 'DateShort', visible: false, width: '100px' },
    { name: 'documentNum', text: "Fakturanr.", visible: false, width: '100px' },
    { name: 'amountCur', text: "Beløb i valuta", format: 'Amount', width: '100px' },
    { name: 'currencyISO', text: "Valuta", width: '60px' },
    { name: 'voucherCategoryName', text: "Kategori", visible: false, width: '100px' },
    { name: 'costAccountNum', text: "Kontering", width: '100px' },
    { name: 'costAccountDescription', text: "Kontering navn", width: '120px' },
    { name: 'costAccountTaxCode', text: "Kontering momskode", visible: false, width: '80px' },
    { name: 'projectCostTypeDescription', text: "Projektart", visible: false, width: '100px' },
    { name: 'approverShortName', text: "Godkender", width: '70px' },
    { name: 'dimension', text: "Afdeling", width: '70px' },
    { name: 'txt', text: "Tekst", width: '120px' },
    { name:'salesFromDate', text: "Fra dato salg", format: 'DateShort', width: '100px', allowFiltering: false, allowSearching: false },
    { name:'salesToDate', text: "Til dato salg", format: 'DateShort', width: '100px', allowFiltering: false, allowSearching: false },
    { name:'salesIsBOM', text: "Bogpakke salg?", format: 'CheckBox' },
    { name: 'channel', text: "Kanal id", visible: false, width: '80px' },
    { name: 'channelType', text: "Kanal type", visible: false, width: '80px' },
    { name: 'channelName', text: "Kanal", visible: false, width: '80px' },
    { name: 'recieved', text: "Modtaget", format: 'DateLong', visible: false },
    { name: 'created', text: "Oprettet", format: 'DateLong', orderBy: 'Descending' },
    { name: 'createdByShortName', text: "Oprettet af", visible: false },
    { name: 'modified', text: "Ændret", format: 'DateLong', visible: false },
    { name: 'modifiedByShortName', text: "Ændret af", visible: false }
  ];

  private toolbarCommands: UiCommand[] = [/*this.uiCmdPostingToggle,*/ this.uiCmdTabToggle];

  private contextMenuCommands: UiCommand[] = [this.uiCmdShowVoucherDetails, uiCmdSeparator,
  this.uiCmdSetStatusCreated, this.uiCmdSetStatusPending, this.uiCmdSetStatusOnHold,
  this.uiCmdSetStatusApproved, this.uiCmdSetStatusAccounted, this.uiCmdSetStatusPosted,
  this.uiCmdSetStatusRejected];

  private keyboardShortcuts: UiKeyboardShortcut[] = [
    { code: 'ArrowRight', ctrl: true, alt: false, shift: false, cmd: this.uiCmdTabToggle },
    { code: 'ArrowRight', ctrl: true, alt: false, shift: true, cmd: this.uiCmdTabToggle },
    { code: 'ArrowLeft', ctrl: true, alt: false, shift: false, cmd: this.uiCmdTabClose },
    { code: 'ArrowLeft', ctrl: true, alt: false, shift: true, cmd: this.uiCmdTabClose },
    //{ code: 'ArrowDown', ctrl: true, alt: false, shift: false, cmd: this.uiCmdPostingToggle },
    //{ code: 'ArrowDown', ctrl: true, alt: false, shift: true, cmd: this.uiCmdPostingToggle },
    //{ code: 'ArrowUp', ctrl: true, alt: false, shift: false, cmd: this.uiCmdPostingClose },
    //{ code: 'ArrowUp', ctrl: true, alt: true, shift: true, cmd: this.uiCmdPostingClose },
    { code: 'KeyO', ctrl: false, alt: true, shift: false, cmd: this.uiCmdShowVoucherDetails, global: true },
    { code: 'KeyX', ctrl: false, alt: true, shift: false, cmd: this.uiCmdShowVoucherDetails, global: true },
    { code: 'KeyM', ctrl: false, alt: true, shift: false, cmd: this.uiCmdSetStatusNextMulti, global: true },
    { code: 'NumpadAdd', ctrl: false, alt: true, shift: false, cmd: this.uiCmdAttachmentZoomIn, global: true },
    //    if (this.attachmentsIsActive) {
    //      this.fileViewerTab.pdfZoomIn();
    //      event.preventDefault();
    { code: 'NumpadSubtract', ctrl: false, alt: true, shift: false, cmd: this.uiCmdAttachmentZoomOut, global: true },
    //this.fileViewerTab.pdfZoomOut();
    { code: 'PageUp', ctrl: false, alt: true, shift: true, cmd: this.uiCmdAttachmentPageUp, global: true },
    //if (this.attachmentsIsActive) {
    //  this.attachmentsPagePrev();
    //  event.preventDefault();
    //}
    { code: 'PageDown', ctrl: false, alt: true, shift: true, cmd: this.uiCmdAttachmentPageDown, global: true },
    // this.attachmentsPageNext();
    { code: 'NumpadAdd', ctrl: false, alt: true, shift: true, cmd: this.uiCmdPaneRightWidthAdd, global: true },
    //this.splitterResize(100);
    { code: 'NumpadSubtract', ctrl: false, alt: true, shift: true, cmd: this.uiCmdPaneRightWidthDecr, global: true },
  ];

  public fileViewerTab: CerAppFileViewerTabComponent;

  constructor(
    private voucherDataService: VoucherDataService,
    private dialog: CerDialogService,
    private chatService: CerAppChatService
  ) {
  }

  public init() {
    this.manage(this.chatService.chats$.subscribe(chat => this.voucherDataService.detailsVmMapRefreshFromChat(chat)));
    this.manage(this.voucherDataService.setupComplete$.subscribe(ok => this.setup()));
    this.manage(this.voucherDataService.detailsVm$.subscribe(vm => this.onDetailVm(vm)));
  }

  private subscriptionManager: Subscription = new Subscription();
  private manage(s: Subscription) {
    this.subscriptionManager.add(s);
  }

  ngOnDestroy() {
    this.subscriptionManager.unsubscribe();
  }

  // Grid support
  private grid: CerGridComponent;
  public initGrid(grid: CerGridComponent) {
    grid.viewMetadata = this.viewMetadata;
    grid.fieldGroupMetadata = this.voucherFieldGroupMetadata;
    grid.fieldMetadata = this.voucherFieldMetadata;
    grid.toolbarCommands = this.toolbarCommands;
    grid.contextMenuCommands = this.contextMenuCommands;
    grid.keyboardShortcuts = this.keyboardShortcuts;
    grid.selectionMode = this.multiSelectApprovalStatusTo ? 'checkboxMulti' : 'row';
    grid.commmand.subscribe(e => this.onCommand(e));
    this.grid = grid;
  }

  private form: CerFormComponent;
  public initForm(form: CerFormComponent) {
    if (form && !this.form) {
      this.form = form;
    }
  }

  private onDetailVm(vm: DetailsVm): void {
    vm?.voucherViewDtoList?.forEach(voucherDto => this.onDetailVmVoucherDto(voucherDto, vm?.chatDtoList));
  }

  private onDetailVmVoucherDto(vmVoucherDto: VoucherDto, chat: ChatDto[]): void {
    var voucherDto: VoucherDto = {} as VoucherDto;
    Object.assign(voucherDto, vmVoucherDto);
    if (voucherDto?.id) {
      var id: number = voucherDto.id;
      var visibleData: any = this.grid?.selection.rowDataById(id);
      if (visibleData) {
        voucherDto.modified.setMilliseconds(0);
        voucherDto.created.setMilliseconds(0);
        if (voucherDto.recieved) {
          voucherDto.recieved.setMilliseconds(0);
        }
        voucherDto.chat?.forEach(c => {
          c.created.setMilliseconds(0); c.modified.setMilliseconds(0);
          c.chatUsers?.forEach(u => { u.created.setMilliseconds(0); u.modified.setMilliseconds(0); });
        });
        if (voucherDto.documentDate === undefined) {
          voucherDto.documentDate = visibleData.documentDate;
        }
        if (voucherDto.transDate === undefined) {
          voucherDto.transDate = visibleData.transDate;
        }
        if (!CerGridSelectionService.rowDataIsEqual(visibleData, voucherDto)) {
          if (voucherDto.modified < visibleData.modified) {
            console.error("Modified date is older than existing data", voucherDto.modified, visibleData.modified);
          }
          else {
            this.grid.selection.rowDataSetById(id, voucherDto);
          }
        }
      }
    }
  }

  public research() {
    this.grid.selection.rowSelectedKeep();
    this.grid.research();
  }

  private researchUpdate(dataUpdate: VoucherUpdateResult) {
    if (dataUpdate.ok && dataUpdate.messageDtoOrig?.id) {
      var messageId: number = dataUpdate.messageDtoOrig.id;
      if (messageId > 0) {
        this.voucherDataService.detailsVmGetPromise(messageId, null).then(detailsVm => {
          this.research();
        });
      }
    }
  }

  // Setup view from data service
  private setup() {
    // Filter my approvals
    this.filterMyVouchers(this.voucherDataService.filterMyVouchers, this.voucherDataService.filterCustInvoice);

    // Approval status
    this.setupApprovalStatus(this.voucherDataService.filterApprovalStatus, this.voucherDataService.filterMyVouchers);

    // Filter channel
    this.setupFilterChannelType(this.voucherDataService.filterChannelType, this.voucherDataService.filterApprovalStatus, this.voucherDataService.filterMyVouchers);
  }

  private filterMyVouchers(filterMyVouchers: boolean, filterCustInvoice: boolean) {
    var apiParams : string[] = (filterCustInvoice ? ['$CustInvoice'] : []);
    if (filterMyVouchers ) {
      apiParams.push('$myVouchers');
    }
    this.viewMetadata.dataApiParams = apiParams;

    if (filterMyVouchers ) {
      this.contextMenuItemRemove(uiCmdSeparator);
      this.contextMenuItemRemove(this.uiCmdSetStatusCreated);
      this.contextMenuItemRemove(this.uiCmdSetStatusPending);
      this.contextMenuItemRemove(this.uiCmdSetStatusApproved);
      this.contextMenuItemRemove(this.uiCmdSetStatusOnHold);
      this.contextMenuItemRemove(this.uiCmdSetStatusAccounted);
      this.contextMenuItemRemove(this.uiCmdSetStatusPosted);
      this.contextMenuItemRemove(this.uiCmdSetStatusRejected);

    }
    var hideApprover: boolean = true;
    if (this.voucherDataService.isVendorVoucher) {
      hideApprover = false;
    }
    if (this.voucherDataService.isExpenseVoucher && this.voucherDataService.filterApprovalStatus != VoucherApprovalStatusAllEnum.Created) {
      hideApprover = false;
    }
    if (filterMyVouchers) {
      hideApprover = true;
    }
    if (hideApprover) {
      this.fieldMetadataByNameHide('approverShortName');
      this.fieldMetadataByNameHide('validationStatus');
    }
  }

  private setupApprovalStatus(filterApprovalStatus: VoucherApprovalStatusAllEnum, filterMyVouchers: boolean) {
    if (filterApprovalStatus == null || filterApprovalStatus == VoucherApprovalStatusAllEnum.All) {
      // Approval status filter => hide status
      this.fieldMetadataByNameShow('statusName');
    }
    else {
      // Filter approval status
      var statusField = this.fieldMetadataByName('status');
      if (statusField) {
        statusField.filterValue = filterApprovalStatus;
        statusField.filterOperator = 'equal';
      }
    }

    // Posted => Show voucher num + posting status
    if (filterApprovalStatus == VoucherApprovalStatusAllEnum.Posted || this.voucherDataService.filterCustInvoice === true) { 
      this.fieldMetadataByNameShow('voucherNum');
      this.fieldMetadataByNameShow('postedStatusName');
    }

    if (filterApprovalStatus == VoucherApprovalStatusAllEnum.Created ||
      filterApprovalStatus == VoucherApprovalStatusAllEnum.Posted ||
      filterApprovalStatus == VoucherApprovalStatusAllEnum.Accounted) {
      this.uiCmdShowVoucherDetails.text = "Vis bilag";
    }

    // Hide context menu commands
    this.contextMenuCommands.filter(cmd => cmd.data?.approvalStatus != null && cmd.data?.approvalStatus == filterApprovalStatus).forEach(cmd =>
      this.contextMenuItemRemove(cmd)
    );

    // Multi select? => Add multi select + statusNext on toolbar
    if (!filterMyVouchers) {
      switch (filterApprovalStatus) {
        case VoucherApprovalStatusAllEnum.Approved:
          this.multiSelectApprovalStatusTo = VoucherApprovalStatusEnum.Accounted;
          break;
        case VoucherApprovalStatusAllEnum.Accounted:
          this.uiCmdSetStatusNextMulti.text = "Bogfør markerede";
          this.multiSelectApprovalStatusTo = VoucherApprovalStatusEnum.Posted;
          break;
      }
      if (this.voucherDataService.filterCustInvoice === true) {
        this.uiCmdSetStatusNextMulti.text = "Bogfør markerede";
        this.multiSelectApprovalStatusTo = VoucherApprovalStatusEnum.Posted;
      }
      if (this.multiSelectApprovalStatusTo) {
        this.toolbarCommands.unshift(this.uiCmdSetStatusNextMulti);
      }
    }
  }

  private setupFilterChannelType(filterChannelType: VoucherMessageChannelTypeEnum, filterApprovalStatus: VoucherApprovalStatusAllEnum, filterMyVouchers: boolean) {
    var isVendorVoucher: boolean = (filterChannelType == VoucherMessageChannelTypeEnum.VendorVoucher);
    if (filterChannelType) {

      // Filter channel type
      var channelTypeField = this.fieldMetadataByName('channelType');
      if (channelTypeField) {
        channelTypeField.filterValue = this.voucherDataService.filterChannelType;
        channelTypeField.filterOperator = 'equal';
      }

      // Manuel voucher
      var salesVoucherFields: string[] = ['salesFromDate', 'salesToDate', 'salesIsBOM'];
      var requiredFields: string[] = ['voucherNum', 'transDate', 'txt'];
      var allEditFields: string[] = salesVoucherFields.concat(requiredFields);
      if (filterChannelType == VoucherMessageChannelTypeEnum.ManualVoucher) {
        this.contextMenuItemRemove(this.uiCmdSetStatusCreated);
        this.viewMetadata.allowCreate = true;
        this.viewMetadata.insertOverride = this.insertPostedVoucher.bind(this);
        this.contextMenuCommands = [];
        this.voucherFieldMetadata.forEach(f =>  { var v = allEditFields.includes(f.name);  f.visibleAdd = v; f.visibleEdit = v });
        this.voucherFieldMetadata.forEach(f => {if (requiredFields.includes(f.name)) {f.required = true}});
        this.fieldMetadataByNameHide('amountCur');
        this.fieldMetadataByNameHide('currencyISO');
        this.fieldMetadataByNameHide('costAccountNum');
        this.fieldMetadataByNameHide('costAccountDescription');
        //this.uiCmdPostingToggle.visible = false;
        //this.toolbarCommands = this.toolbarCommands.filter(cmd => cmd.id != this.uiCmdPostingToggle.id);
        this.keyboardShortcuts = this.keyboardShortcuts.filter(key => this.uiCmdShowVoucherDetails.id != key.cmd.id);
      }
      else {
        this.voucherFieldMetadata=this.voucherFieldMetadata.filter(f => !salesVoucherFields.includes(f.name));
      }

      // Expense voucher => show category
      var expenseVoucher: boolean = (filterChannelType == VoucherMessageChannelTypeEnum.ExpenseVoucher);
      var filterApprovalStatusCreate: boolean = this.voucherDataService.filterApprovalStatus == VoucherApprovalStatusAllEnum.Created;
      if (expenseVoucher || (filterApprovalStatusCreate && filterMyVouchers)) {
        this.fieldMetadataByNameShow('voucherCategoryName');
      }

      // Vendor voucher
      if (isVendorVoucher) {
        this.uiCmdShowVoucherDetails.text = "Rediger godkendelse";
        this.fieldMetadataByName('transDate').text = "Fakturadato";
        this.contextMenuItemRemove(this.uiCmdSetStatusCreated);
      }
      if (isVendorVoucher && !filterMyVouchers) {
        this.fieldMetadataByName('costAccountNum').text = "Projekt";
        this.fieldMetadataByName('costAccountDescription').text = "Projektnavn";
        this.fieldMetadataByNameShow('projectCostTypeDescription');
      }

      // Vendor voucher + my approvals => show balance account
      if (!filterMyVouchers && isVendorVoucher) {
        this.fieldMetadataByNameShow('documentNum');
      }
    }
    if (isVendorVoucher || filterMyVouchers) {
      this.fieldMetadataByNameShow('balanceAccountNum');
      this.fieldMetadataByNameShow('balanceAccountDescription');
      this.fieldMetadataByNameShow('balanceAccountTaxCode');
    }
  }

  private fieldMetadataByName(fieldName: string): FieldMetadata {
    return this.voucherFieldMetadata.find(f => f.name == fieldName);
  }

  private fieldMetadataByNameShow(fieldName: string, visible: boolean = true) {
    var fieldMeta: FieldMetadata = this.fieldMetadataByName(fieldName);
    if (fieldMeta) {
      fieldMeta.visible = visible;
    }
  }

  private fieldMetadataByNameHide(fieldName: string, visible: boolean = false) {
    this.fieldMetadataByNameShow(fieldName, visible);
  }

  private contextMenuItemRemove(uiCmd: UiCommand) {
    var index = this.contextMenuCommands.indexOf(uiCmd);
    if (index !== -1) {
      this.contextMenuCommands.splice(index, 1);
    }
  }

  private onCommand(e: UiCommandEvent): void {
    /*if (e.actionType == UiActionTypeEnum.QueryCellInfo) {
      this.voucherGridQueryCellInfo(e.args);
      return;
    }
    else*/ if (e.commandId == this.uiCmdShowVoucherDetails.id) {
      var dto: VoucherDto = this.dtoFromArgs(e.args);
      if (dto?.msgId && !this.voucherDataService.isManualVoucher) {
        this.voucherDetailsOpen(dto, false);
      }
    }
    else if (e.actionType == UiActionTypeEnum.ContextMenuItem) {
      if (e.command?.data?.messageStatus) {
        this.setMessageStatus(e);
      }
      else if (e.command?.data?.approvalStatus) {
        this.setApprovalStatusSingle(e);
      }
    }
    else if (e.actionType == UiActionTypeEnum.RowDoubleClick) {
      var dto: VoucherDto = this.dtoFromArgs(e.args);
      if (dto?.msgId && !this.voucherDataService.isManualVoucher) {
        var calledFromChat: boolean = e.columnName == 'chat';
        this.voucherDetailsOpen(dto, calledFromChat);
      }
      /*
      else {
        this.grid.commandService.contextMenuOpen(e.args);
      }
      */
    }
    else if (e.source == UiCommandSourceEnum.ActionBegin) {
      switch (e.actionType) {
        case UiActionTypeEnum.Save:
          //this.createPostedVoucher(e.args.data);
          break;
      }
    }
    else if (e.source == UiCommandSourceEnum.ActionComplete) {
      switch (e.actionType) {
        case UiActionTypeEnum.RowSelect:
          var msgId : number = this.dtoFromArgs(e.args)?.msgId;
          if (msgId) {
            this.voucherDataService.detailsVmGetFromMap(msgId);
          }
          break;
      }
    }
    else if (e.actionType == UiActionTypeEnum.ContextMenuOpen) {
      this.onContextMenuOpen(e.args);
    }
    else {
      switch (e.commandId) {
        case this.uiCmdSetStatusNextMulti.id:
          this.setApprovalStatusMulti(e);
          break;
        case this.uiCmdShowVoucherDetails.id:
          var calledFromChat: boolean = e.columnName == 'chat';
          this.voucherDetailsOpen(this.dtoFromArgs(e.args), calledFromChat);
          break;
      }
    }
  }

  private dtoFromArgs(args: any, useSelectedRow: boolean = true): VoucherDto {
    var dto: VoucherDto = args?.rowData;
    if (dto == null) {
      dto = args?.rowInfo?.rowData;
    }
    if (dto == null) {
      dto = args?.data;
    }
    if (dto == null) {
      dto = args?.rowData;
    }
    if (dto == null && args.args) {
      dto = this.dtoFromArgs(args.args, useSelectedRow);
    }
    if (dto == null && useSelectedRow) {
      dto = <VoucherDto>this.grid.rowSelectedData();
    }
    return dto;
  }

  private voucherGridQueryCellInfo(args: QueryCellInfoEventArgs) {
    /*
    if (args.column.field === 'chatCount') {
      var data: any = args.data;
      var detailsVm: DetailsVm = null;
      var vm: DetailsVm = this.voucherDataService.detailsVmMap.get(data.msgId);
      this.grid.editCellService.chatColumnRefresh(detailsVm?.chatDtoList);
    }
    */
  }

  // Grid context menu open - disable menu items
  private onContextMenuOpen(args: ContextMenuOpenEventArgs) {
    var voucherDto: VoucherDto = this.dtoFromArgs(args, false);
    var approvalStatusFrom: VoucherApprovalStatusEnum = voucherDto?.status;
    this.grid.grid.contextMenuItems.forEach(item => {
      var id = (item as any).id;
      if (id?.length > 0) {
        var cmd: UiCommand = this.contextMenuCommands.find(cmd => cmd.id == id);
        if (cmd) {
          var approvalStatusTo: VoucherApprovalStatusEnum = (cmd?.data?.approvalStatus);
          if (approvalStatusTo) {
            var enabled: boolean = VoucherHelper.isApprovalStatusChangeEnabled(approvalStatusTo, approvalStatusFrom);
            this.grid.grid.contextMenuModule.contextMenu.enableItems([id], enabled, true);
          }

          var messageStatusTo: VoucherMessageStatusEnum = (cmd?.data?.messageStatus);
          if (messageStatusTo) {
            var enabled: boolean = VoucherHelper.isMessageStatusChangeEnabled(VoucherMessageStatusEnum.Voucher, messageStatusTo, approvalStatusTo);
            this.grid.grid.contextMenuModule.contextMenu.enableItems([id], enabled, true);
          }
        }
      }
    });
  }

  // Set message status
  private setApprovalStatusMulti(e: UiCommandEvent) {
    if (this.multiSelectApprovalStatusTo) {
      var selectedData: any = this.grid?.selection.rowSelectedData();

      var voucherDtoList: VoucherDto[] = this.grid.selection.rowsSelectedData() as VoucherDto[];
      if (voucherDtoList.length > 0) {
        var voucherIdList: number[] = [];
        var messageIdSingle: number = (voucherDtoList?.length == 1) ? voucherDtoList[0].msgId : null;
        voucherDtoList.forEach(voucherDto => voucherIdList.push(voucherDto.id));
        this.setApprovalStatus(voucherIdList, this.multiSelectApprovalStatusTo, messageIdSingle);
      }
      else {
        this.dialog.snackBarError("Ingen bilag markeret");
      }
    }
    else {
      this.dialog.snackBarError("Ingen multi status opdatering tilladt");
    }
  }

  private setApprovalStatusSingle(e: UiCommandEvent) {
    var voucherDto: VoucherDto = this.dtoFromArgs(e);
    var approvalStatusTo: VoucherApprovalStatusEnum = e.command?.data?.approvalStatus;
    if (voucherDto.id && approvalStatusTo) {
      this.setApprovalStatus([voucherDto.id], approvalStatusTo, voucherDto.msgId);
    }
  }

  private setApprovalStatus(voucherIdList: number[], toApprovalStatus: VoucherApprovalStatusEnum, messageIdSingle: number) {
    if (voucherIdList?.length > 0) {
      if (toApprovalStatus) {
        this.voucherDataService.apiSetApprovalStatus(voucherIdList, toApprovalStatus).then(
          result => {
            if (messageIdSingle && this.voucherDataService.filterApprovalStatus == VoucherApprovalStatusAllEnum.All) {
              this.voucherDataService.detailsVmGet(messageIdSingle); // Will refresh the row in the grid
            }
            else {
              this.research();
            }
          });
      }
      else {
        this.dialog.snackBarError("Ingen ny status findes");
      }
    }
    else {
      this.dialog.snackBarError("Ingen bilag markeret");
    }
  }

  private setMessageStatus(e: UiCommandEvent) {
    var voucherDto: VoucherDto = this.dtoFromArgs(e);
    var messageStatusTo: VoucherMessageStatusEnum = e.command?.data?.messageStatus;
    if (voucherDto?.msgId && messageStatusTo) {
      this.voucherDataService.messageDtoGetByMsgId(voucherDto.msgId).then(messageDto => {
        this.voucherDataService.apiSetMessageStatus(messageDto, messageStatusTo).then(
          result => this.researchUpdate(result)
        );
      });
    }
  }

  private insertPostedVoucher(newVoucher: VoucherDto) {
    if (this.voucherDataService.isManualVoucher) {
      this.voucherDataService.apiCreatePostedVoucher(newVoucher.voucherNum, newVoucher.transDate, newVoucher.txt,
        newVoucher.salesFromDate, newVoucher.salesToDate, newVoucher.salesIsBOM
      ).then(messageId => this.research());
    }
  }

  // Open details dialog
  private voucherDetailsOpen(voucherDto: VoucherDto, calledFromChat: boolean) {
    if (voucherDto?.msgId) {
      this.voucherDataService.messageDtoGetByMsgId(voucherDto.msgId).then(messageDto => {
        var config: any = this.voucherDetailsDialogGetConfig(messageDto, calledFromChat);
        this.voucherDataService.voucherDetailsOpen(messageDto, config).then((result) => {
          this.researchUpdate(result);
        });
      });
    }
    else {
      this.dialog.snackBarError("Åbn bilag er ikke muligt");
    }
  }

  // Open voucher details dialog config
  public voucherDetailsDialogAttachmentIdxDefault: number = 0;
  private voucherDetailsDialogGetConfig(messageDto: MessageDto, showChat: boolean): any {
    //if (!this.detailFormIsActive) {
    //this.detailFormIsActive = true;
    var attachmentsActive: boolean = (this.form.panesService.visible('right') || messageDto.status == VoucherMessageStatusEnum.Inbox || messageDto.status == VoucherMessageStatusEnum.Voucher);
    var calledFromVoucher: boolean = (messageDto.status == VoucherMessageStatusEnum.Voucher);
    var editPosting: boolean = !calledFromVoucher;
    var config: any = this.voucherDataService.voucherDetailsDialogGetConfig(messageDto, attachmentsActive, calledFromVoucher, editPosting, this.voucherDetailsDialogAttachmentIdxDefault, false, showChat);
    return config;
  }

  // Show chat
  public chatOpen() {
    this.form.panesService.show('right');
    this.fileViewerTab.chatOpen();

  }
}

